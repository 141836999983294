// @ts-check

//import Vue from 'vue'
//import Vuex from 'vuex'

//Vue.use(Vuex)

const UserProperty = Object.freeze({
  stateName: 'user',
  storageName: 'user',
  mutation: 'setLocalStorageUser'
})

const IdTokenProperty = Object.freeze({
  stateName: 'idToken',
  storageName: 'id_token',
  mutation: 'setLocalStorageIdToken'
})

const IsNewHookDoneProperty = Object.freeze({
  stateName: 'isNewHookDone',
  storageName: 'isNewHookDone',
  mutation: 'setLocalStorageIsNewHookDone'
})

const NeedPasswordRedirectProperty = Object.freeze({
  stateName: 'needPasswordRedirect',
  storageName: 'needPasswordRedirect',
  mutation: 'setLocalStorageNeedPasswordRedirect'
})

const StoreProperties = Object.freeze({
  USER: UserProperty,
  ID_TOKEN: IdTokenProperty,
  IS_NEWHOOK_DONE: IsNewHookDoneProperty,
  NEED_PASSWORD_REDIRECT: NeedPasswordRedirectProperty
})

// /**
//  * @param {{ [x: string]: any; }} state
//  * @param {Readonly<{ stateName: string; storageName: string; mutation: string; }>} property
//  * @param {any} value
//  */
// function mutateStateProperty(state, property, value) {
//   if (!value) {
//     window.localStorage.removeItem(property.storageName)
//   }

//   window.localStorage.setItem(property.storageName, JSON.stringify(value))
//   state[property.stateName] = value
// }

// function mapState() {
//   return Object.entries(StoreProperties).reduce((p, c) => {
//     const object = window.localStorage.getItem(c[1].storageName)

//     p[c[1].stateName] = object ? JSON.parse(object) : undefined

//     return p
//   }, {})
// }

// function mapMutations() {
//   return Object.entries(StoreProperties).reduce((p, c) => {
//     p[c[1].mutation] = (state, value) => mutateStateProperty(state, c[1], value)
//     // {
//     //   if (!value) {
//     //     window.localStorage.removeItem(c[1].storageName)
//     //   }

//     //   window.localStorage.setItem(c[1].storageName, JSON.stringify(value))
//     //   state[c[1].stateName] = value
//     // }

//     return p
//   }, {})
// }

// const store = new Vuex.Store({
//   state: {
//     ...mapState()
//   },
//   mutations: {
//     ...mapMutations()
//   }
// })

/**
 * @param {Readonly<{ stateName: string; storageName: string; mutation: string; }>} property
 */
function getStorageItem(property) {
  const object = window.localStorage.getItem(property.storageName)

  return object ? JSON.parse(object) : undefined
}

/**
 * @param {Readonly<{ stateName: string; storageName: string; mutation: string; }>} property
 * @param {any} value
 */
function setStorageItem(property, value) {
  if (!value) {
    window.localStorage.removeItem(property.storageName)
  } else {
    window.localStorage.setItem(property.storageName, JSON.stringify(value))
  }
}

class LocalStorage {
  /** @type { any } */
  get user() {
    // return store.state[StoreProperties.USER.stateName]
    return getStorageItem(StoreProperties.USER)
  }

  set user(value) {
    // store.commit(StoreProperties.USER.mutation, value)
    setStorageItem(StoreProperties.USER, value)
  }

  get idToken() {
    // return store.state[StoreProperties.ID_TOKEN.stateName]
    return getStorageItem(StoreProperties.ID_TOKEN)
  }

  set idToken(value) {
    // store.commit(StoreProperties.ID_TOKEN.mutation, value)
    setStorageItem(StoreProperties.ID_TOKEN, value)
  }

  get isNewHookDone() {
    // return store.state[StoreProperties.IS_NEWHOOK_DONE.stateName]
    return getStorageItem(StoreProperties.IS_NEWHOOK_DONE)
  }

  set isNewHookDone(value) {
    // store.commit(StoreProperties.IS_NEWHOOK_DONE.mutation, value)
    setStorageItem(StoreProperties.IS_NEWHOOK_DONE, value)
  }

  get needPasswordRedirect() {
    // return store.state[StoreProperties.NEED_PASSWORD_REDIRECT.stateName]
    return getStorageItem(StoreProperties.NEED_PASSWORD_REDIRECT)
  }

  set needPasswordRedirect(value) {
    // store.commit(StoreProperties.NEED_PASSWORD_REDIRECT.mutation, value)
    setStorageItem(StoreProperties.NEED_PASSWORD_REDIRECT, value)
  }
}

export default new LocalStorage()
