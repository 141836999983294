// @ts-check

import * as msal from '@azure/msal-browser'
import Vue from 'vue'
import AuthConfig from '../config/auth-config.js'
import LocalStorage from '../store/local-storage.js'

Vue.mixin({
  beforeCreate() {
    /** @type any */
    const options = this.$options
    if (options.authService) Vue.prototype.$authService = options.authService
    else if (options.parent && options.parent.$authService)
      Vue.prototype.$authService = options.parent.$authService
  }
})

export default class AuthService {
  /** @type { msal.AccountInfo } */
  account = undefined

  /** @type { Promise } */
  ctorProm = undefined

  /** @type { object } */
  _idToken = undefined

  get idToken() {
    if (!this._idToken) {
      this._idToken = LocalStorage.idToken
    }

    return this._idToken
  }

  set idToken(value) {
    this._idToken = value
    LocalStorage.idToken = value
  }

  // Make a plugin https://vuejs.org/v2/guide/plugins.html (opt) + config as constructor parameters
  constructor() {
    console.debug('[Auth::ctor] ->')
    this.config = new AuthConfig()

    const msalConfig = {
      auth: {
        clientId: this.config.clientid,
        validateAuthority: false,
        redirectUri: window.location.origin + this.config.redirectPath,
        postLogoutRedirectUri: window.location.origin,
        navigateToLoginRequestUrl: false,
        authority: this.config.authority + this.config.b2cSigninPolicy,
        knownAuthorities: [
          this.config.authority + this.config.b2cSignupPolicy,
          this.config.authority + this.config.b2cSigninPolicy,
          this.config.authority + this.config.b2cResetPasswordPolicy
        ]
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
      }
    }

    this.app = new msal.PublicClientApplication(msalConfig)
    console.debug('[Auth::ctor] PublicClientApplication instanciated')

    // Register Callbacks for Redirect flow
    console.debug('[Auth::ctor] handleRedirectPromise')
    this.ctorProm = this.app
      .handleRedirectPromise()
      .then(this.handleResponse)
      .catch(this.handleAuthError)

    console.debug('[Auth::ctor] <-')
  }

  /**
   * @param { msal.AuthenticationResult } response
   */
  handleResponse = response => {
    console.debug('[Auth::handleResponse] ->')
    console.debug('[Auth::handleResponse] response: ', response)
    let accountObj

    if (response !== null) {
      accountObj = response.account
      this.idToken = response.idTokenClaims
      console.debug('[Auth::handleResponse] response.account: ', accountObj)
    } else {
      accountObj = this.getAccount()
      console.debug('[Auth::handleResponse] getAccount(): ', accountObj)
    }

    console.debug('[Auth::handleResponse] account: ', accountObj)
    this.account = accountObj

    console.debug('[Auth::handleResponse] <-')
  }

  /** @returns {import('@azure/msal-browser').AccountInfo}*/
  getAccount() {
    const currentAccounts = this.app.getAllAccounts()

    if (!currentAccounts || currentAccounts.length === 0) {
      // No user signed in
      return
    } else if (currentAccounts.length > 1) {
      // More than one user signed in, find desired user with getAccountByUsername(username)
      console.warn(
        `[Auth::getAccount] More than on user signed in. (${currentAccounts.length})`
      )
      console.debug('[Auth::getAccount] accounts: ', currentAccounts)
    } else {
      return currentAccounts[0]
    }
  }

  handleAuthError = authErr => {
    console.log('[Auth::handleAuthError] Error: ', authErr)

    if (authErr.errorMessage.startsWith('AADB2C90118')) {
      console.log('[Auth] Password reset...')
      LocalStorage.needPasswordRedirect = 'true'
    } else if (authErr.errorMessage.startsWith('AADB2C90077')) {
      console.log('[Auth] Need login...')
      //this.loginRedirect()
      this.logout()
    }
  }

  signupRedirect() {
    console.debug('[Auth::signupRedirect]')
    this.app
      .loginRedirect({
        authority: this.config.authority + this.config.b2cSignupPolicy,
        scopes: this.config.b2cScopes
      })
      .then(() =>
        console.debug('[Auth::signupRedirect] loginRedirect promise resolved.')
      )
      .catch(err =>
        console.debug(
          '[Auth::signupRedirect] loginRedirect promise rejected: ',
          err
        )
      )
  }

  loginRedirect() {
    console.debug('[Auth::loginRedirect]')
    this.app
      .loginRedirect({
        authority: this.config.authority + this.config.b2cSigninPolicy,
        scopes: this.config.b2cScopes
      })
      .then(() =>
        console.debug('[Auth::loginRedirect] loginRedirect promise resolved.')
      )
      .catch(err =>
        console.debug(
          '[Auth::loginRedirect] loginRedirect promise rejected: ',
          err
        )
      )
  }

  logout() {
    console.debug('[Auth::logout]')
    // this.app._user = null
    LocalStorage.user = undefined
    LocalStorage.isNewHookDone = undefined
    // this.app.authority = this.config.authority + this.config.b2cSigninPolicy
    this.app
      .logout()
      .then(() => console.debug('[Auth::logout] logout promise resolved.'))
      .catch(err =>
        console.debug('[Auth::logout] logout promise rejected: ', err)
      )
  }

  passwordResetRedirect() {
    console.log('[Auth] password reset asked !')
    LocalStorage.needPasswordRedirect = 'false'

    this.app
      .loginRedirect({
        authority: this.config.authority + this.config.b2cResetPasswordPolicy,
        scopes: this.config.b2cScopes
      })
      .then(() =>
        console.debug(
          '[Auth::passwordRedirect] loginRedirect promise resolved.'
        )
      )
      .catch(err =>
        console.debug(
          '[Auth::passwordRedirect] loginRedirect promise rejected: ',
          err
        )
      )
  }

  needPasswordResetRedirect() {
    return LocalStorage.needPasswordRedirect == 'true'
  }

  /** @type { Promise<string | void> } */
  apiProm = undefined

  getAPIToken() {
    const tokenRequest = {
      scopes: this.config.b2cScopes,
      redirectUri: window.location.origin + this.config.redirectPath,
      account: this.getAccount()
    }

    if (this.apiProm == undefined) {
      this.apiProm = this.app
        .acquireTokenSilent(tokenRequest)
        .then(response => response.accessToken)
        .catch(error => {
          console.error(
            'silent token acquisition fails. acquiring token using redirect',
            error
          )
          // fallback to interaction when silent call fails
          // this.loginRedirect()

          this.app
            .acquireTokenRedirect(tokenRequest)
            .then(() =>
              console.debug(
                '[Auth::getAPIToken] acquireTokenRedirect promise resolved.'
              )
            )
            .catch(err =>
              console.debug(
                '[Auth::passwordRedirect] acquireTokenRedirect promise rejected: ',
                err
              )
            )
        })
        .finally(() => (this.apiProm = undefined))
    }

    return this.apiProm
  }

  // Utility
  async getUser() {
    console.debug('[Auth::getUser] ->')
    await this.ctorProm

    let userInfo = undefined

    console.debug('[Auth::getUser] this.idToken: ', this.idToken)

    if (this.idToken) {
      if (this.idToken.sid == undefined || this.idToken.userName == undefined) {
        // console.log('Need to get a new access token')
        console.debug('[Auth::getUser] acquireTokenSilent')
        await this.app
          .acquireTokenSilent({
            scopes: this.config.b2cScopes,
            redirectUri: window.location.origin + this.config.redirectPath,
            account: this.account
          })
          .then(response => {
            console.debug(
              '[Auth::getUser] acquireTokenSilent resolved: ',
              response
            )
            //console.log(accessToken)
            this.idToken = response.idTokenClaims
          })
          .catch(error => {
            console.debug(
              '[Auth::getUser] acquireTokenSilent rejected: ',
              error
            )
            console.error('[Auth] Error while access token renew', error)
            this.loginRedirect()
          })
      }

      // TODO: What if it rejected ?
      userInfo = {
        id: this.idToken.sub,
        fullName: this.idToken.name,
        email: this.idToken.emails[0],
        username: this.idToken.family_name
      }
      LocalStorage.user = userInfo
    }

    console.debug('[Auth::getUser] userInfo: ', userInfo)
    console.debug('[Auth::getUser] <-')
    return userInfo
  }

  async isLoggedIn() {
    await this.ctorProm

    const loggedIn = !!this.getAccount()
    console.debug('[Auth::isLoggedIn] account: ', loggedIn)
    return loggedIn
  }

  // return true if user has just signup
  userIsNew() {
    return this.idToken ? this.idToken.newUser == 'true' : false
  }

  // return true if user has just signup
  getUserId() {
    return this.idToken ? this.idToken.sub : undefined
  }
}
