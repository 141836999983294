const TAG_SPAN = 'SPAN'
const ATTR_DISABLED = 'disabled'
const ATTR_ROLE = 'role'
const VALUE_ROLE_STATUS = 'status'

function displayLoader(el, { value, oldValue, modifiers }) {
  if (value != oldValue) {
    if (value) {
      const span = el.ownerDocument.createElement(TAG_SPAN)
      span.className = 'spinner-border spinner-border-sm'
      span.setAttribute(ATTR_ROLE, VALUE_ROLE_STATUS)
      span.setAttribute('aria-hidden', 'true')

      el.prepend(span)

      if (modifiers.disable === true) {
        if (el.hasAttribute(ATTR_DISABLED)) {
          el.dataset._disabled_ = el.getAttribute(ATTR_DISABLED)
        }

        el.setAttribute(ATTR_DISABLED, 'disabled')
      }
    } else if (
      el.children.length > 0 &&
      el.children[0].tagName == TAG_SPAN &&
      el.children[0].getAttribute(ATTR_ROLE) == VALUE_ROLE_STATUS
    ) {
      el.children[0].remove()

      if (modifiers.disable === true) {
        if (el.dataset._disabled_) {
          el.setAttribute(ATTR_DISABLED, el.dataset._disabled_)
          delete el.dataset._disabled_
        } else {
          el.removeAttribute(ATTR_DISABLED)
        }
      }
    }
  }
}

export default {
  bind: displayLoader,
  update: displayLoader
}
