import Vue from 'vue'
import Router from 'vue-router'
import { isLoggedIn, isNew, requireAuth } from './utils/auth-utils'
import store from './store'

Vue.use(Router)

export const Routes = Object.freeze({
  HOME: { name: 'home' },
  MENU: { name: 'menu' },
  CHARTER_TOU: { name: 'charter-tou' },
  DEFINE_USER_TYPE: { name: 'define-user-type' },
  REGISTER_DRIVER: { name: 'register-driver' },
  REGISTER_PROVIDER: { name: 'register-provider' },
  REGISTRATION_FINISHED: { name: 'registration-finished' },
  SEARCH: { name: 'search' },
  SEARCH_RESULTS: { name: 'search-results' },
  ACCOUNT: { name: 'account' },
  ACCOUNT_EDIT: { name: 'account-edit' },
  ADD_PAYMENT_METHOD: { name: 'add-payment-method' },
  EDIT_PAYMENT_METHOD: { name: 'edit-payment-method' },
  MY_VEHICLE: { name: 'my-vehicle' },
  MY_CHARGING_POINTS: { name: 'my-charging-points' },
  MY_CHARGING_POINT: { name: 'my-charging-point' },
  MY_CHARGING_POINT_AVAILABILITY_EDIT: {
    name: 'my-charging-point-availability-edit'
  },
  MY_CHARGING_POINT_DETAILS_EDIT: { name: 'my-charging-point-details-edit' },
  MY_BOOKINGS: { name: 'my-bookings' },
  MY_COMPLAINTS: { name: 'my-complaints' },
  ABOUT: { name: 'about' },
  FAQ: { name: 'faq' },
  CALLBACK: { name: 'callback' },
  TESTS: { name: 'tests' },
  FILEUPLOAD_TESTS: { name: 'fileupload-tests' }
})

const ComponentFactory = Object.freeze({
  MENU: () => import(/* webpackChunkName: "menu" */ './views/Menu.vue'),
  CHARTER_TOU: () =>
    import(/* webpackChunkName: "charter" */ './views/CharterAndTou.vue'),
  LOADER: () => import(/* webpackChunkName: "loader" */ './views/Loader.vue'),
  HOME: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
  DEFINE_USER_TYPE: () =>
    import(/* webpackChunkName: "define-user" */ './views/DefineUserType.vue'),
  REGISTER_DRIVER: () =>
    import(
      /* webpackChunkName: "register-driver" */ './views/RegisterDriver.vue'
    ),
  REGISTER_PROVIDER: () =>
    import(
      /* webpackChunkName: "register-provider" */ './views/RegisterProvider.vue'
    ),
  REGISTRATION_FINISHED: () =>
    import(
      /* webpackChunkName: "registration-finished" */ './views/RegistrationFinished.vue'
    ),
  SEARCH: () => import(/* webpackChunkName: "search" */ './views/Search.vue'),
  SEARCH_RESULTS: () =>
    import(
      /* webpackChunkName: "search-results" */ './views/SearchResults.vue'
    ),
  ACCOUNT: () =>
    import(/* webpackChunkName: "account" */ './views/Account.vue'),
  ACCOUNT_EDIT: () =>
    import(/* webpackChunkName: "account-edit" */ './views/AccountEdit.vue'),
  ADD_PAYMENT_METHOD: () =>
    import(/* webpackChunkName: "edit-pm" */ './views/EditPaymentMethod.vue'),
  EDIT_PAYMENT_METHOD: () =>
    import(/* webpackChunkName: "edit-pm" */ './views/EditPaymentMethod.vue'),
  MY_VEHICLE: () =>
    import(/* webpackChunkName: "my-vehicle" */ './views/MyVehicle.vue'),
  MY_CHARGING_POINTS: () =>
    import(/* webpackChunkName: "my-cps" */ './views/MyChargingPoints.vue'),
  MY_CHARGING_POINT: () =>
    import(/* webpackChunkName: "my-cp" */ './views/MyChargingPoint.vue'),
  MY_CHARGING_POINT_AVAILABILITY_EDIT: () =>
    import(
      /* webpackChunkName: "my-cp-availability-edit" */ './views/MyChargingPointAvailabilityEdit.vue'
    ),
  MY_CHARGING_POINT_DETAILS_EDIT: () =>
    import(
      /* webpackChunkName: "my-cp-details-edit" */ './views/MyChargingPointDetailsEdit.vue'
    ),
  MY_BOOKINGS: () =>
    import(/* webpackChunkName: "my-bookings" */ './views/MyBookings.vue'),
  MY_COMPLAINTS: () =>
    import(/* webpackChunkName: "my-complaints" */ './views/MyComplaints.vue'),
  ABOUT: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
  FAQ: () => import(/* webpackChunkName: "faq" */ './views/Faq.vue')
})

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      ...Routes.HOME,
      components: {
        default: ComponentFactory.HOME,
        loader: ComponentFactory.LOADER
      }
    },
    {
      path: '/menu',
      ...Routes.MENU,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      }
    },
    {
      path: '/charter-tou-acceptance',
      ...Routes.CHARTER_TOU,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      }
    },
    {
      path: '/register/define-user-type',
      ...Routes.DEFINE_USER_TYPE,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.DEFINE_USER_TYPE,
        menu: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      }
    },
    {
      path: '/register/driver',
      ...Routes.REGISTER_DRIVER,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.REGISTER_DRIVER,
        menu: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      }
    },
    {
      path: '/register/provider',
      ...Routes.REGISTER_PROVIDER,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.REGISTER_PROVIDER,
        menu: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      }
    },
    {
      path: '/register/finished',
      ...Routes.REGISTRATION_FINISHED,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.REGISTRATION_FINISHED,
        menu: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      }
    },
    {
      path: '/search',
      ...Routes.SEARCH,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.SEARCH,
        menu: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      }
    },
    {
      path: '/search/results',
      ...Routes.SEARCH_RESULTS,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.SEARCH_RESULTS,
        menu: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      }
    },
    {
      path: '/account',
      ...Routes.ACCOUNT,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.ACCOUNT,
        menu: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      }
    },
    {
      path: '/account/edit',
      ...Routes.ACCOUNT_EDIT,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.ACCOUNT_EDIT,
        menu: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      }
    },
    {
      path: '/account/payment-methods/add',
      ...Routes.ADD_PAYMENT_METHOD,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.ADD_PAYMENT_METHOD,
        menu: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      }
    },
    {
      path: '/account/payment-methods/:id',
      ...Routes.EDIT_PAYMENT_METHOD,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.EDIT_PAYMENT_METHOD,
        menu: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      },
      props: { default: true, menu: false, 'charter-tou': false }
    },
    {
      path: '/account/vehicles/:id',
      ...Routes.MY_VEHICLE,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.MY_VEHICLE,
        menu: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      },
      props: { default: true, menu: false, 'charter-tou': false }
    },
    {
      path: '/account/charging-points',
      ...Routes.MY_CHARGING_POINTS,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.MY_CHARGING_POINTS,
        menu: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      }
    },
    {
      path: '/account/charging-points/:id',
      ...Routes.MY_CHARGING_POINT,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.MY_CHARGING_POINT,
        menu: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      },
      props: { default: true, menu: false, 'charter-tou': false }
    },
    {
      path: '/account/charging-points/:id/availability',
      ...Routes.MY_CHARGING_POINT_AVAILABILITY_EDIT,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.MY_CHARGING_POINT_AVAILABILITY_EDIT,
        menu: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      },
      props: { default: true, menu: false, 'charter-tou': false }
    },
    {
      path: '/account/charging-points/:id/details',
      ...Routes.MY_CHARGING_POINT_DETAILS_EDIT,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.MY_CHARGING_POINT_DETAILS_EDIT,
        menu: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      },
      props: { default: true, menu: false, 'charter-tou': false }
    },
    {
      path: '/account/bookings',
      ...Routes.MY_BOOKINGS,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.MY_BOOKINGS,
        menu: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      }
    },
    {
      path: '/account/complaints',
      ...Routes.MY_COMPLAINTS,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.MY_COMPLAINTS,
        menu: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      }
    },
    {
      path: '/about',
      ...Routes.ABOUT,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.ABOUT,
        menu: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      }
    },
    {
      path: '/faq',
      ...Routes.FAQ,
      beforeEnter: requireAuth,
      components: {
        default: ComponentFactory.FAQ,
        menu: ComponentFactory.MENU,
        'charter-tou': ComponentFactory.CHARTER_TOU,
        loader: ComponentFactory.LOADER
      }
    },
    {
      path: '/callback',
      ...Routes.CALLBACK,
      component: () =>
        import(/* webpackChunkName: "callback" */ './views/auth/callback.vue')
    },
    {
      path: '/tests',
      ...Routes.TESTS,
      component: () =>
        import(/* webpackChunkName: "tests" */ './views/auth/tests.vue')
    },
    {
      path: '/fileuploadtests',
      ...Routes.FILEUPLOAD_TESTS,
      //beforeEnter: requireAuth,
      component: () =>
        import(/* webpackChunkName: "fu-tests" */ './views/FileUploadTests.vue')
    }
  ]
})

// Called only once before ANY route enter
const removeIsNewHook = router.beforeEach(async (to, from, next) => {
  // TODO: See if callback can NOT be a Controller
  if (to.name != Routes.CALLBACK.name && (await isLoggedIn())) {
    if (isNew() && !store.getters.isNewHookDone) {
      console.log('[Router] First login, must display ToU.')
      store.commit('setUserInfo', { tou: false })
      store.dispatch('setIsNewHookDone')
    }

    removeIsNewHook()
  }

  next()
})

router.afterEach((to /*, from*/) => {
  if (to.name != Routes.MENU.name) {
    store.commit('hideMenu')
  }
})

export default router
