import { DataFetchStatus } from '@/assets/js/Constants.js'
import LocalStorage from '@/store/local-storage'

let fetchStatus = {
  info: DataFetchStatus.Unfetched,
  photo: DataFetchStatus.Unfetched
}

const userInfo = {
  state: {
    id: '',
    fullName: '',
    email: '',
    username: '',
    tou: true,
    photoURL: require('@/assets/svg/evenir-profil-default.svg')
  },
  mutations: {
    setUserInfo(state, userInfo) {
      Object.assign(state, userInfo)
    },
    setUserPhoto(state, photoURL) {
      URL.revokeObjectURL(state.photoURL)

      state.photoURL = photoURL
    }
  },
  actions: {
    acceptTermsOfUse({ commit, state }, { api }) {
      const userInfo = { ...state }
      userInfo.tou = true

      return api
        .putUserInfoAsync(userInfo) // TODO: Create API endpoint to accept ToU
        .then(() => commit('setUserInfo', userInfo))
        .catch(err => {
          console.error('[STORE] Uncaught error while accepting TOU: ', err)

          return Promise.reject(err)
        })
    },
    saveUserInfo({ commit }, { userInfo, api }) {
      return api
        .putUserInfoAsync(userInfo)
        .then(() => commit('setUserInfo', userInfo))
        .catch(err => {
          console.error(
            '[STORE] Uncaught error while updating user info: ',
            err
          )

          return Promise.reject(err)
        })
    },
    loadUserInfo({ state, commit, dispatch }, { api, refresh }) {
      if (fetchStatus.info == DataFetchStatus.Unfetched || refresh) {
        fetchStatus.info = DataFetchStatus.Pending

        return api
          .getUserInfoAsync()
          .then(userInfo => {
            if (!userInfo || !userInfo.email) {
              userInfo = LocalStorage.user
              dispatch('saveUserInfo', { userInfo, api })
            } else {
              commit('setUserInfo', userInfo)
              fetchStatus.info = DataFetchStatus.Fetched
            }

            return Promise.resolve(state)
          })
          .catch(err => {
            console.error(
              '[STORE] Uncaught error while updating user info: ',
              err
            )
            fetchStatus.info = DataFetchStatus.Unfetched

            return Promise.reject(err)
          })
      }

      return Promise.resolve(state)
    },
    saveUserPhoto({ commit }, { photo, api }) {
      return api
        .putUserPhotoAsync(photo.file)
        .then(() => {
          commit('setUserPhoto', photo.url)
        })
        .catch(err => {
          console.error(
            '[STORE] Uncaught error while updating user info: ',
            err
          )

          return Promise.reject(err)
        })
    },
    loadUserPhoto({ commit, state }, { api, refresh }) {
      if (fetchStatus.photo == DataFetchStatus.Unfetched || refresh) {
        fetchStatus.photo = DataFetchStatus.Pending

        return api
          .getUserPhotoContentDataURLAsync()
          .then(photoURL => {
            commit('setUserPhoto', photoURL)
            fetchStatus.photo = DataFetchStatus.Fetched

            return Promise.resolve(state.photoURL)
          })
          .catch(err => {
            console.error(
              '[STORE] Uncaught error while updating user photo: ',
              err
            )
            fetchStatus.photo = DataFetchStatus.Unfetched

            return Promise.reject(err)
          })
      }

      return Promise.resolve(state.photoURL)
    }
  }
}

export default userInfo
