import Vue from 'vue'
import Vuex from 'vuex'

import references from './store/references.js'
import userInfo from './store/user-info.js'
import driverProfile from './store/driver-profile.js'
import providerProfile from './store/provider-profile.js'
import documents from './store/documents.js'
import search from './store/search.js'
import LocalStorage from './store/local-storage.js'

import { DataFetchStatus } from '@/assets/js/Constants.js'
import { instance } from 'vue-stripe-elements-plus'

import { isLoggedIn, getUserId } from './utils/auth-utils'

Vue.use(Vuex)

let fetchStatus = {
  creditCards: DataFetchStatus.Unfetched,
  bookings: {
    status: DataFetchStatus.Unfetched,
    pending: undefined
  }
}

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    creditCards: [],
    iban: {
      number: undefined,
      details: {}
    },
    bookings: [],
    menuVisible: false,
    appLoaded: false
  },
  modules: {
    userInfo,
    documents,
    references,
    driverProfile,
    providerProfile,
    search
  },
  getters: {
    userId: state => state.userInfo.id,
    vehicles: state => state.driverProfile.vehicles,
    hasVehicles: (_, getters) => getters.vehicles.length > 0,
    chargingPoints: state => state.providerProfile.chargingPoints,
    hasChargingPoints: (_, getters) => getters.chargingPoints.length > 0,
    hasNothing: (_, getters) =>
      !getters.hasVehicles && !getters.hasChargingPoints,
    isCreditCardSet: state => !!state.creditCards.length,
    getCreditCard: state => id => {
      const idx = state.creditCards.findIndex(pm => pm.id == id)

      return idx > -1 ? state.creditCards[idx] : undefined
    },
    isIbanSet: state => !!state.iban.number,
    driverBookings: (state, getters) =>
      state.bookings.filter(
        booking => booking.vehicle.userId == getters.userId
      ),
    providerBookings: (state, getters) =>
      state.bookings.filter(
        booking => booking.chargingPoint.ownerId == getters.userId
      ),
    charterVisible: state => !state.userInfo.tou,
    isNewHookDone: async () =>
      (await isLoggedIn()) && LocalStorage.isNewHookDone == getUserId(),
    canDoSearch: (state, getters) => {
      const vehicles = getters.vehicles

      return (
        state.documents.idCard.isValid !== false &&
        vehicles.length &&
        vehicles
          .map(v => {
            const rc = getters.getVehicleRegistrationCertificate(v.id)

            return rc ? rc.isValid : undefined
          })
          .reduce((p, c) => p || c !== false, false) &&
        getters.isCreditCardSet
      )
    }
  },
  mutations: {
    setCreditCard(state, pm) {
      const idx = state.creditCards.findIndex(_pm => _pm.id == pm.id)

      if (idx == -1) {
        state.creditCards.push(pm)
      } else {
        state.creditCards.splice(idx, 1, pm)
      }
    },
    setCreditCards(state, pms) {
      state.creditCards = pms
    },
    updateCreditCardDetails(state, { cardId, expDate, owner }) {
      const idx = state.creditCards.findIndex(pm => pm.id == cardId)

      if (idx > -1) {
        state.creditCards[idx].card.exp_month = expDate.month
        state.creditCards[idx].card.exp_year = expDate.year
        state.creditCards[idx].billing_details.name = owner
      }
    },
    deleteCreditCard(state, id) {
      const idx = state.creditCards.findIndex(pm => pm.id == id)

      if (idx > -1) {
        state.creditCards.splice(idx, 1)
      }
    },
    setIban(state, iban) {
      state.iban.number = iban.iban
      state.iban.details = iban.paymentMethod.billing_details
    },
    addBooking(state, booking) {
      state.bookings.push(booking)
    },
    setBookings(state, bookings) {
      state.bookings = bookings
    },
    showMenu(state) {
      state.menuVisible = true
    },
    hideMenu(state) {
      state.menuVisible = false
    },
    setAppLoaded(state) {
      state.appLoaded = true
    }
  },
  actions: {
    registerDriver({ commit }, { vehicle, documents }) {
      commit('addVehicle', vehicle)
      commit('setIdCard', documents.idCard)
      commit('setRegistrationCertificate', {
        vId: vehicle.id,
        doc: documents.registrationCert
      })
    },
    registerProvider({ commit }, { chargingPoint, documents }) {
      commit('addChargingPoint', chargingPoint)
      commit('setIdCard', documents.idCard)
      commit('setProofOfAddress', {
        cpId: chargingPoint.id,
        poa: documents.proofOfAddress
      })
    },
    createCreditCard({ commit }, { api, pm }) {
      return api
        .getSetupIntentAsync()
        .then(clientSecret =>
          instance.confirmCardSetup(clientSecret, {
            payment_method: pm
          })
        )
        .then(result => {
          if (result.error) {
            throw new Error('Error while handling card setup: ' + result.error)
          }

          return api.postUserPaymentMethodAsync(result.setupIntent)
        })
        .then(pm => {
          commit('setCreditCard', pm)

          return Promise.resolve()
        })
        .catch(err => {
          console.error(
            `[STORE] Uncaught error while creating credit card: `,
            err
          )

          return Promise.reject(err)
        })
    },
    updateCreditCard({ commit }, { api, pm }) {
      return api
        .putUserPaymentMethodAsync(pm)
        .then(() => {
          commit('setCreditCard', pm)

          return Promise.resolve()
        })
        .catch(err => {
          console.error(
            `[STORE] Uncaught error while updating payment method: `,
            err
          )

          return Promise.reject(err)
        })
    },
    loadCreditCard({ commit }, { api, id }) {
      return api
        .getUserPaymentMethodAsync(id)
        .then(pm => commit('setCreditCard', pm))
        .catch(err =>
          console.error(
            `[STORE] Uncaught error while loading payment method [${id}]: `,
            err
          )
        )
    },
    loadCreditCards({ commit }, { api, refresh }) {
      if (fetchStatus.creditCards == DataFetchStatus.Unfetched || refresh) {
        fetchStatus.creditCards = DataFetchStatus.Pending

        return api
          .getUserPaymentMethodsAsync()
          .then(creditCards => {
            commit('setCreditCards', creditCards)
            fetchStatus.creditCards = DataFetchStatus.Fetched

            return Promise.resolve()
          })
          .catch(err => {
            console.error(
              '[STORE] Uncaught error while updating payment methods: ',
              err
            )
            fetchStatus.creditCards = DataFetchStatus.Unfetched

            return Promise.reject(err)
          })
      }
    },
    deleteCreditCard({ commit }, { api, id }) {
      return api
        .deletePaymentMethodAsync(id)
        .then(() => {
          commit('deleteCreditCard', id)

          return Promise.resolve()
        })
        .catch(err => {
          console.error(
            `[STORE] Uncaught error while deleting vehicle [${id}]: `,
            err
          )

          return Promise.reject()
        })
    },
    createIban({ commit }, { api, ibanInfo }) {
      console.log(ibanInfo)
      const { name, email, city, country, address, postalCode, IBAN } = ibanInfo
      const pm = {
        type: 'sepa_debit',
        // sepa_debit: this.$refs.iban.$refs.element._element,
        sepa_debit: {
          iban: IBAN
        },
        billing_details: {
          name: name,
          email: email,
          address: {
            city: city,
            country: country,
            line1: address,
            postal_code: postalCode
          }
        }
      }

      return instance
        .createPaymentMethod(pm)
        .then(result => {
          if (result.error) {
            console.error(
              "[STORE] Couldn't create IBAN payment method (Stripe): ",
              result.error
            )

            return Promise.reject(result.error)
          }

          console.log(
            '[STORE] Payment method created (Stripe): ',
            result.paymentMethod
          )

          return api.putUserIbanAsync(result.paymentMethod, IBAN).then(() =>
            commit('setIban', {
              iban: IBAN,
              paymentMethod: result.paymentMethod
            })
          )
        })
        .catch(err => {
          if (err instanceof Error) {
            console.error('[STORE] Uncaught error while saving IBAN: ', err)
          }

          return Promise.reject(err)
        })
    },
    loadIban({ commit, state }, { api }) {
      return api
        .getUserIbanAsync()
        .then(iban => {
          if (iban != null) {
            commit('setIban', iban)
          }

          return state.iban
        })
        .catch(err =>
          console.error(`[STORE] Uncaught error while loading IBAN: `, err)
        )
    },
    createBooking({ commit, state, getters }, { api, booking }) {
      const {
        cp,
        time,
        price,
        basePrice,
        estimatedDistance,
        stripePaymentIntentId
      } = booking

      const { id, ownerId, address } = cp.properties
      const vehicle = getters.vehicles[0]

      return api
        .postUserBookingAsync({
          chargingPoint: {
            id,
            ownerId,
            address
          },
          vehicle: {
            id: vehicle.id,
            name: vehicle.displayValue,
            username: state.userInfo.username
          },
          time,
          price,
          basePrice,
          estimatedDistance,
          stripePaymentIntentId: stripePaymentIntentId
        })
        .then(booking => commit('addBooking', booking))
        .catch(err => {
          console.error('[STORE] Uncaught error while ctrating booking: ', err)
          fetchStatus.bookings.status = DataFetchStatus.Unfetched

          return Promise.reject(err)
        })
    },
    loadBookings({ state, commit }, { api, refresh }) {
      if (fetchStatus.bookings.status == DataFetchStatus.Pending) {
        return fetchStatus.bookings.pending
      } else if (
        fetchStatus.bookings.status == DataFetchStatus.Unfetched ||
        refresh
      ) {
        fetchStatus.bookings.status = DataFetchStatus.Pending

        fetchStatus.bookings.pending = api
          .getUserBookingsAsync()
          .then(bookings => {
            commit('setBookings', bookings)
            fetchStatus.bookings.status = DataFetchStatus.Fetched

            return bookings
          })
          .catch(err => {
            console.error(
              '[STORE] Uncaught error while updating store bookings: ',
              err
            )
            fetchStatus.bookings.status = DataFetchStatus.Unfetched

            return Promise.reject(err)
          })

        return fetchStatus.bookings.pending
      } else {
        return state.bookings
      }
    },
    createComplaint(context, { api, bkId, reason }) {
      console.log(`[STORE] Creating complaint: `, bkId, reason)
      // Call API Service
      console.log(api)
      return Promise.resolve()
    },
    async setIsNewHookDone() {
      if (await isLoggedIn()) {
        LocalStorage.isNewHookDone = getUserId()
      }
    }
  }
})

var storageUser = LocalStorage.user

if (storageUser) {
  store.commit('setUserInfo', storageUser)
}

console.log('[STORE] Store initialized: ', store)

export default store
