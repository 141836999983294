export default class AuthConfig {
  constructor() {
    this.clientid = process.env.VUE_APP_AUTH_B2C_CLIENTID
    this.redirectPath = '/'
    this.authority = process.env.VUE_APP_AUTH_B2C_AUTHORITY
    this.b2cSigninPolicy = 'b2c_1_Signin'
    this.b2cSignupPolicy = 'b2c_1_Signup'
    this.b2cResetPasswordPolicy = 'B2C_1_PasswordReinit'
    this.graphscopes = ['user.read']
    this.graphendpoint = 'https://graph.microsoft.com/v1.0/me'
    this.b2cScopes = [process.env.VUE_APP_AUTH_API_SCOPE, 'offline_access']
  }
}
