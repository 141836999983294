import { DataFetchStatus } from '@/assets/js/Constants.js'

let fetchStatus = DataFetchStatus.Unfetched
let fetchPending = undefined

function setDocument(document, { file, url, type, isValid }) {
  document.file = file
  document.type = type
  document.url = url
  document.isValid = isValid
}

function addElement(dictionary, key, value) {
  dictionary.keys.push(key)
  dictionary.values.push(value)
  dictionary.size++
}

function setElement(dictionary, key, value) {
  const idx = dictionary.keys.indexOf(key)

  if (idx > -1) {
    dictionary.values[idx] = value
  } else {
    addElement(dictionary, key, value)
  }
}

function getElement(dictionary, key) {
  const idx = dictionary.keys.indexOf(key)

  return idx > -1 ? dictionary.values[idx] : undefined
}

const documents = {
  state: {
    idCard: {
      file: undefined,
      type: undefined,
      url: undefined,
      isValid: undefined
    },
    proofOfAddresses: { size: 0, keys: [], values: [] }, // dictionary
    registrationCertificates: { size: 0, keys: [], values: [] } // dictionary
  },
  getters: {
    getIdCard: state => state.idCard,
    getChargingPointProofOfAddress: state => cpId =>
      getElement(state.proofOfAddresses, cpId),
    getVehicleRegistrationCertificate: state => vId =>
      getElement(state.registrationCertificates, vId)
  },
  mutations: {
    setIdCard(state, idCard) {
      setDocument(state.idCard, idCard)
    },
    setProofOfAddress(state, { cpId, poa }) {
      setElement(state.proofOfAddresses, cpId, poa)
    },
    setRegistrationCertificate(state, { vId, cert }) {
      setElement(state.registrationCertificates, vId, cert)
    }
  },
  actions: {
    loadIdCard({ state, commit }, { api, refresh }) {
      if (fetchStatus == DataFetchStatus.Pending) {
        return fetchPending
      } else if (fetchStatus == DataFetchStatus.Unfetched || refresh) {
        fetchStatus = DataFetchStatus.Pending

        fetchPending = api
          .getUserIdCardAsync()
          .then(idCard => api.getDocumentContent(idCard).then(() => idCard))
          .then(idCard => {
            commit('setIdCard', idCard)
            fetchStatus = DataFetchStatus.Fetched

            return idCard
          })
          .catch(err => {
            console.error(
              '[STORE] Uncaught error while updating user ID card: ',
              err
            )
            fetchStatus = DataFetchStatus.Unfetched

            return Promise.reject(err)
          })

        return fetchPending
      } else {
        return state.idCard
      }
    },
    loadRegistrationCertificate({ commit }, { api, vId }) {
      return api
        .getVehicleRegistrationCertificateAsync(vId)
        .then(cert => {
          cert.url = undefined
          commit('setRegistrationCertificate', { vId, cert })

          return cert
        })
        .catch(err => {
          console.error(
            `[STORE] Uncaught error while updating [${vId}]'s registration certificate: `,
            err
          )

          return Promise.reject(err)
        })
    },
    loadProofOfAddress({ commit }, { api, cpId }) {
      return api
        .getChargingPointProofOfAddressAsync(cpId)
        .then(poa => {
          commit('setProofOfAddress', { cpId, poa })

          return poa
        })
        .catch(err => {
          console.error(
            `[STORE] Uncaught error while updating [${cpId}]'s proof of address: `,
            err
          )

          return Promise.reject(err)
        })
    }
  }
}

export default documents
