// @ts-check
// TODO: See how to remove rule exceptions (eslint-disable)

import Vue from 'vue'
// eslint-disable-next-line no-unused-vars
import AuthService from './auth'
import { DefaultAvailability } from '../mixins/cp-availability'
import {
  // eslint-disable-next-line no-unused-vars
  Parkability,
  // eslint-disable-next-line no-unused-vars
  Accessibility,
  // eslint-disable-next-line no-unused-vars
  ComplaintKind,
  // eslint-disable-next-line no-unused-vars
  DefaultComplaintSubject,
  // eslint-disable-next-line no-unused-vars
  BookingComplaintSubject
} from '../assets/js/Constants.js'
import _ from 'lodash'
// eslint-disable-next-line no-unused-vars
import { Search, SearchResultCP } from '../utils/search-utils.js'

Vue.mixin({
  beforeCreate() {
    /** @type any */
    const options = this.$options
    if (options.apiService) Vue.prototype.$apiService = options.apiService
    else if (options.parent && options.parent.$apiService)
      Vue.prototype.$apiService = options.parent.$apiService
  }
})

export default class ApiService {
  /**
   * @constructor
   * @param {AuthService} authService
   */
  constructor(authService) {
    this.authService = authService
    this._userProm = undefined
  }

  async _getUser() {
    console.debug('[API::_getUser] ->')

    if (!this._userProm) {
      console.debug('[API::_getUser] getUser from Auth service')

      this._userProm = this.authService
        .getUser()
        .then(userInfo => {
          console.debug('[API::_getUSer] userInfo: ', userInfo)

          const { fullName, id, email } = userInfo

          return {
            id,
            name: fullName,
            email
          }
        })
        .finally(() => (this._userProm = undefined))

      console.debug('[API::_getUser] Set _userProm: ', this._userProm)
    }

    console.debug('[API::_getUser] <-')
    return await this._userProm
  }

  async _getUserId() {
    return (await this._getUser()).id
  }

  /**
   * @param {"GET" | "POST" | "PUT" | "DELETE" | undefined} [method]
   * @param {any} [body]
   */
  async _getFetchOptionsAsync(method, body) {
    const token = await this.authService.getAPIToken()
    const options = {
      headers: new Headers({
        Authorization: 'Bearer ' + token
      })
    }

    if (method) {
      options.method = method
    }

    if (body) {
      options.body = JSON.stringify(body)
    }

    return options
  }

  // Reference types

  static RefVehicleTypesEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_Referentials_Base}/${process.env.VUE_APP_API_Method_Referentials_VehicleTypes}`
  )

  static RefChargingPointTypesEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_Referentials_Base}/${process.env.VUE_APP_API_Method_Referentials_ChargingPointTypes}`
  )

  /**
   * @typedef {object} VehicleVersion
   * @property {number} autonomyKM
   * @property {number} batteryPowerKWH
   * @property {number} maxBatteryLoadSpeedupKVA
   * @property {number} maxBatteryLoadSpeedupKWH
   */
  /**
   * @typedef {object} VehicleType
   * @property {string} brand
   * @property {string} label
   * @property {VehicleVersion[]} versions
   */
  /**
   * @returns {Promise<VehicleType[]>}
   */
  async getVehicleTypesAsync() {
    console.log('[API] Retrieving vehicle types...')
    let vTypes = []
    const url = ApiService.RefVehicleTypesEndpoint()

    try {
      const options = await this._getFetchOptionsAsync()
      const response = await fetch(url, options)

      vTypes = await response.json()
    } catch (err) {
      console.error('[API] Error while retrieving vehicle models: ', err)
    }

    return vTypes
  }

  /**
   * @typedef {object} ChargingPointVersion
   * @property {number} powerKVA
   * @property {"Lente" | "Rapide" | "Accélérée"} loadType
   */
  /**
   * @typedef {object} ChargingPointType
   * @property {string} baseType
   * @property {ChargingPointVersion[]} versions
   */
  /**
   * @returns {Promise<ChargingPointType[]>}
   */
  async getChargingPointTypesAsync() {
    console.log('[API] Retrieving charging point types...')
    let cpTypes = []
    const url = ApiService.RefChargingPointTypesEndpoint()

    try {
      const options = await this._getFetchOptionsAsync()
      const response = await fetch(url, options)

      cpTypes = await response.json()
    } catch (err) {
      console.error('[API] Error while retriving charging point types: ', err)
    }

    return cpTypes
  }

  //User vehicles

  /**
   * @typedef {object} ApiVehicle
   * @property {string} rowKey
   * @property {{ rowKey: string }} batteryPower
   * @property {string} plateNumber
   */

  /**
   * @typedef {object} Vehicle
   * @property {string} id
   * @property {{ rowKey: string }} batteryPower
   * @property {string} plateNumber
   */

  /**
   * @param {ApiVehicle} apiVehicle

   * @returns {Vehicle}
   */
  static MapVehicleFromApi(apiVehicle) {
    const vehicle = {
      id: apiVehicle.rowKey,
      ...apiVehicle
    }

    delete vehicle.rowKey

    return vehicle
  }

  static UserVehiclesEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_User_Base}/\${ userId }/${process.env.VUE_APP_API_Method_User_UserVehicles}`
  )

  static UserVehicleEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_User_Base}/\${ userId }/${process.env.VUE_APP_API_Method_User_UserVehicles}/\${ vehicleId }`
  )

  /**
   * @param {Vehicle} vehicle
   */
  async postUserVehicleAsync(vehicle) {
    console.log('[API] Adding new user vehicle...')
    const url = ApiService.UserVehiclesEndpoint({
      userId: await this._getUserId()
    })

    try {
      const options = await this._getFetchOptionsAsync('POST', {
        VehicleRowKey: vehicle.batteryPower.rowKey,
        PlateNumber: vehicle.plateNumber
      })

      return fetch(url, options)
        .then(response => response.json())
        .then(vehicle => ApiService.MapVehicleFromApi(vehicle))
    } catch (err) {
      console.error('[API] Uncaught error while updating vehicles: ', err)
      throw new Error('Uncaught error while updating vehicles: ' + err)
    }
  }

  /**
   * @returns {Promise<Vehicle[]>}
   */
  async getUserVehiclesAsync() {
    console.log('[API] Retrieving user vehicles...')
    let vehicles = []
    const vehiclesUrl = ApiService.UserVehiclesEndpoint({
      userId: await this._getUserId()
    })

    try {
      const options = await this._getFetchOptionsAsync()
      const response = await fetch(vehiclesUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while retrieving user vehicles: ${response.status} ${response.statusText}`
        )
      } else {
        vehicles = await response
          .json()
          .then(vehicles => vehicles.map(ApiService.MapVehicleFromApi))
      }
    } catch (err) {
      console.error(
        '[API] Uncaught error while retrieving user vehicles: ',
        err
      )
    }

    return vehicles
  }

  /**
   * @param {string} vId
   * @returns {Promise<Vehicle>}
   */
  async getUserVehicleAsync(vId) {
    console.log(`[API] Retrieving user vehicle [${vId}]...`)
    let vehicle
    const vehicleUrl = ApiService.UserVehicleEndpoint({
      userId: await this._getUserId(),
      vehicleId: vId
    })

    try {
      const options = await this._getFetchOptionsAsync()
      const response = await fetch(vehicleUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while retrieving user vehicle: ${response.status} ${response.statusText}`
        )
      } else {
        vehicle = await response
          .json()
          .then(vehicle => ApiService.MapVehicleFromApi(vehicle))
      }
    } catch (err) {
      console.error('[API] Uncaught error while retrieving user vehicle: ', err)
    }

    return vehicle
  }

  /**
   * @param {string} vId
   * @returns {Promise<any>}
   */
  async deleteUserVehicleAsync(vId) {
    console.log(`[API] Deleting user vehicle [${vId}]...`)
    const vehicleUrl = ApiService.UserVehicleEndpoint({
      userId: await this._getUserId(),
      vehicleId: vId
    })

    try {
      const options = await this._getFetchOptionsAsync('DELETE')
      const response = await fetch(vehicleUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while deleting user vehicle: ${response.status} ${response.statusText}`
        )

        return Promise.reject()
      }
    } catch (err) {
      console.error('[API] Uncaught error while deleting user vehicle: ', err)

      return Promise.reject()
    }

    return Promise.resolve()
  }

  static VehicleRegistrationCertificateEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_Vehicle_Base}/\${ vehicleId }/registrationcert`
  )

  static VehicleRegistrationCertificateContentEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_Vehicle_Base}/\${ vehicleId }/registrationcert/content`
  )

  /**
   * @param {string} id
   * @param {File | Blob} file
   */
  async putVehicleRegistrationCertificateAsync(id, file) {
    console.log('[API] Updating vehicle registration certificate...')
    const userInfoUrl = ApiService.VehicleRegistrationCertificateContentEndpoint(
      {
        vehicleId: id
      }
    )

    try {
      const formData = new FormData()
      formData.append('file', file)
      const options = await this._getFetchOptionsAsync('PUT')
      options.body = formData
      const response = await fetch(userInfoUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while puting vehicle registration certificate: ${response.status} ${response.statusText}`
        )
        throw new Error(
          `Error while puting vehicle registration certificate: ${response.status} ${response.statusText}`
        )
      }
    } catch (err) {
      console.error(
        '[API] Uncaught error while updating vehicle registration certificate: ',
        err
      )
      throw new Error(
        'Uncaught error while updating vehicle registration certificate: ' + err
      )
    }
  }

  /**
   * @typedef {Object} Document
   * @property {File | Blob} file
   * @property {String} type
   * @property {string} url
   * @property {boolean | undefined} isValid
   */

  /**
   * @typedef {Object} ApiDocument
   * @property {String} fileId
   * @property {String} contentType
   * @property {Date} validatedAt
   * @property {boolean} isValid
   */

  /**
   * @param {ApiDocument} data
   * @param {Document} file
   * @param {string} fileUrl
   */
  static MapDocumentFromApi(data, file, fileUrl) {
    if (!!data && data.fileId) {
      file.type = data.contentType
      file.url = fileUrl + '/content'
      file.isValid = data.validatedAt ? data.isValid : undefined
    }
  }

  /**
   * @param {string} id
   * @returns {Promise<Document>}
   */
  async getVehicleRegistrationCertificateAsync(id) {
    console.log(`[API] Retrieving vehicle [${id}] registration certificate...`)
    let cert = {
      file: undefined,
      type: undefined,
      url: undefined,
      isValid: undefined
    }
    const url = ApiService.VehicleRegistrationCertificateEndpoint({
      vehicleId: id
    })

    try {
      const options = await this._getFetchOptionsAsync()
      const response = await fetch(url, options)

      if (!response.ok) {
        console.error(
          `[API] Error while fetching vehicle registration certificate: ${response.status} ${response.statusText}`
        )
      } else {
        const data = await response.json()

        ApiService.MapDocumentFromApi(data, cert, url)
      }
    } catch (err) {
      console.error(
        `[API] Uncaught error while retrieving vehicle registration certificate: ` +
          err
      )
    }

    return cert
  }

  /**
   * @param {string} vId
   */
  async getVechicleRegistrationCertificateContentDataURLAsync(vId) {
    console.log(
      `[API] Retrieving vechicle [${vId}] registration certificate content...`
    )
    let certURL = ''
    const contentUrl = ApiService.VehicleRegistrationCertificateContentEndpoint(
      {
        vehicleId: vId
      }
    )

    try {
      const options = await this._getFetchOptionsAsync('GET')
      const response = await fetch(contentUrl, options)

      if (!response.ok) {
        const msg = `Error while retrieving vehicle registration certificate content: ${response.status} ${response.statusText}`
        console.error('[API] ' + msg)
        throw new Error(msg)
      } else {
        certURL = await response.blob().then(blob => URL.createObjectURL(blob))
      }
    } catch (err) {
      const msg =
        'Uncaught error while retrieving registration certificate content: '
      console.error('[API] ' + msg, err)
      throw new Error(msg + err.message)
    }

    return certURL
  }

  // User charging points

  /**
   * @typedef {object} ApiDayAvailability
   * @property {boolean} available
   * @property {string} startTime
   * @property {string} endTime
   */
  /**
   * @typedef {object} ApiWeekAvailability
   * @property {ApiDayAvailability} monday
   * @property {ApiDayAvailability} tuesday
   * @property {ApiDayAvailability} wednesday
   * @property {ApiDayAvailability} thursday
   * @property {ApiDayAvailability} friday
   * @property {ApiDayAvailability} saturday
   * @property {ApiDayAvailability} sunday
   */
  /**
   * @typedef {object} ApiAvailability
   * @property {boolean} paused
   * @property {ApiWeekAvailability} days
   */
  /**
   * @typedef {object} ApiChargingPoint
   * @property {string=} customerId
   * @property {string} id
   * @property {string} address
   * @property {{ type: "Point", coordinates: number[] }} location
   * @property {string} plugType
   * @property {boolean} public
   * @property {number} price
   * @property {Parkability} parkability
   * @property {Accessibility} accessibility
   * @property {string} details
   * @property {ApiAvailability} availability
   * @property {any[]} bookings
   * @property {string[]} subscribers
   */

  /**
   * @typedef {object} ChargingPointContext
   * @property {Parkability} parkability
   * @property {Accessibility} accessibility
   * @property {string} details
   */
  /**
   * @typedef {object} ChargingPoint
   * @property {string} id
   * @property {string} address
   * @property {{ lon: number, lat: number }} position
   * @property {string} type
   * @property {string} power
   * @property {boolean} private
   * @property {number} price
   * @property {ChargingPointContext} context
   * @property {any} availability
   * @property {any[]} bookings
   * @property {string[]} subscribers
   */

  /**
   * @param {string} plugType
   */
  static MapTypePowerFromApi(plugType) {
    const [type, power] = plugType ? plugType.split('|') : ['', '']

    return { type, power: power.replace('KVA', '') }
  }

  static UserChargingPointsEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_User_Base}/\${ userId }/${process.env.VUE_APP_API_Method_User_UserChargingPoints}`
  )

  static UserChargingPointEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_User_Base}/\${ userId }/${process.env.VUE_APP_API_Method_User_UserChargingPoints}/\${ cpId }`
  )

  /**
   * @param {ChargingPoint} cpOptions
   *
   * @returns {Promise<ChargingPoint>}
   */
  async postUserChargingPointAsync(cpOptions) {
    console.log('[API] Adding new user charging point...')
    const url = ApiService.UserChargingPointsEndpoint({
      userId: await this._getUserId()
    })

    try {
      const apiCP = ApiService.MapChargingPointToApi(cpOptions)
      const options = await this._getFetchOptionsAsync('POST', apiCP)

      return fetch(url, options).then(async response => {
        const apiCP = await response.json()

        return ApiService.MapChargingPointFromApi(apiCP)
      })
    } catch (err) {
      console.error('[API] Uncaught error while adding charging point: ', err)
      throw new Error('Uncaught error while adding charging point: ' + err)
    }
  }

  /**
   * @param {ApiChargingPoint} apiCP
   *
   * @returns {ChargingPoint}
   */
  static MapChargingPointFromApi(apiCP) {
    const [lon, lat] = apiCP.location.coordinates
    const cp = {
      id: apiCP.id,
      address: apiCP.address,
      position: { lon, lat },
      ...ApiService.MapTypePowerFromApi(apiCP.plugType),
      private: !apiCP.public,
      price: apiCP.price,
      context: {
        parkability: apiCP.parkability,
        accessibility: apiCP.accessibility,
        details: apiCP.details
      },
      availability: apiCP.availability
        ? ApiService.GetAvailability(apiCP.availability) // TODO: Remove patch, availability should always be an object
        : DefaultAvailability(true),
      bookings: [], // TODO: Map bbokings
      subscribers: apiCP.subscribers || []
    }

    // TODO: Remove patch, 'paused' should always be defined
    if (cp.availability.paused === undefined) {
      cp.availability = {
        paused: false,
        days: cp.availability
      }
    }

    return cp
  }

  /**
   * @param {ChargingPoint} cp
   *
   * @returns {ApiChargingPoint}
   */
  static MapChargingPointToApi(cp) {
    /** @type {"Point"} */
    const locationType = 'Point'
    const apiCP = {
      id: cp.id,
      address: cp.address,
      location: {
        type: locationType,
        coordinates: [cp.position.lon, cp.position.lat]
      },
      plugType: `${cp.type}|${cp.power}KVA`,
      public: !cp.private,
      price: cp.price,
      parkability: cp.context.parkability,
      accessibility: cp.context.accessibility,
      details: cp.context.details,
      availability: cp.availability,
      bookings: [],
      subscribers: cp.subscribers || []
    }

    return apiCP
  }

  /**
   * @param {ApiAvailability | string} availability
   */
  // TODO: Remove patch, availability should always be an object
  static GetAvailability(availability) {
    return typeof availability === 'string'
      ? JSON.parse(availability)
      : availability
  }

  /**
   * @returns {Promise<ChargingPoint[]>}
   */
  async getUserChargingPointsAsync() {
    console.log('[API] Retrieving user charging points...')
    let chargingPoints = []
    const chargingPointsUrl = ApiService.UserChargingPointsEndpoint({
      userId: await this._getUserId()
    })

    try {
      const options = await this._getFetchOptionsAsync()
      const response = await fetch(chargingPointsUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while fetching user charging points: ${response.status} ${response.statusText}`
        )
      } else {
        const data = await response.json()

        chargingPoints = data.map(ApiService.MapChargingPointFromApi)
      }
    } catch (err) {
      console.error(
        '[API] Uncaught error while retrieving user charging points: ',
        err
      )
    }

    return chargingPoints
  }

  /**
   * @param {string} id
   *
   * @returns {Promise<ChargingPoint>}
   */
  async getCurrentUserChargingPointAsync(id) {
    console.log(`[API] Retrieving user charging point [${id}]...`)
    let chargingPoint = undefined
    const chargingPointUrl = ApiService.UserChargingPointEndpoint({
      userId: await this._getUserId(),
      cpId: id
    })

    try {
      const options = await this._getFetchOptionsAsync()
      const response = await fetch(chargingPointUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while fetching user charging point [${id}]: ${response.status} ${response.statusText}`
        )
      } else {
        let data = await response.json()

        chargingPoint = ApiService.MapChargingPointFromApi(data)
      }
    } catch (err) {
      console.error(
        `[API] Uncaught error while retrieving user charging point [${id}]: `,
        err
      )
    }

    return chargingPoint
  }

  /**
   * @param {string} id
   *
   * @returns {Promise<ChargingPoint>}
   */
  async getUserChargingPointAsync(userId, id) {
    console.log(
      `[API] Retrieving charging point [${id}] of user [${userId}]...`
    )
    let chargingPoint = undefined
    const chargingPointUrl = ApiService.UserChargingPointEndpoint({
      userId: userId,
      cpId: id
    })

    try {
      const options = await this._getFetchOptionsAsync()
      const response = await fetch(chargingPointUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while fetching charging point [${id}] of user [${userId}]: ${response.status} ${response.statusText}`
        )
      } else {
        let data = await response.json()

        chargingPoint = ApiService.MapChargingPointFromApi(data)
      }
    } catch (err) {
      console.error(
        `[API] Uncaught error while retrieving user charging point [${id}]: `,
        err
      )
    }

    return chargingPoint
  }

  /**
   * @param {string} id
   *
   * @returns {Promise<any>}
   */
  async deleteUserChargingPointAsync(id) {
    console.log(`[API] Deleting user charging point [${id}]...`)
    const vehicleUrl = ApiService.UserChargingPointEndpoint({
      userId: await this._getUserId(),
      cpId: id
    })

    try {
      const options = await this._getFetchOptionsAsync('DELETE')
      const response = await fetch(vehicleUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while deleting user charging point: ${response.status} ${response.statusText}`
        )

        return Promise.reject()
      }
    } catch (err) {
      console.error(
        '[API] Uncaught error while deleting user charging point: ',
        err
      )

      return Promise.reject()
    }

    return Promise.resolve()
  }

  static ChargingPointPhotosEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_ChargingPoint_Base}/\${ cpId }/photos`
  )

  static ChargingPointPhotoEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_ChargingPoint_Base}/\${ cpId }/photos/\${ fileId }`
  )

  static ChargingPointPhotoContentEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_ChargingPoint_Base}/\${ cpId }/photos/\${ fileId }/content`
  )

  /**
   * @param {string} id
   * @param {File | Blob} file
   */
  async postChargingPointPhotoAsync(id, file) {
    console.log('[API] Adding charging point photo...')
    const url = ApiService.ChargingPointPhotosEndpoint({ cpId: id })

    try {
      const formData = new FormData()
      formData.append('file', file)
      const options = await this._getFetchOptionsAsync('POST')
      options.body = formData
      const response = await fetch(url, options)

      if (!response.ok) {
        const msg = `Error while uploading charging point photo: ${response.status} ${response.statusText}`
        console.error('[API] ' + msg)
        throw new Error(msg)
      }

      return response.json()
    } catch (err) {
      const msg = '[API] Uncaught error while uploading charging point photo: '
      console.error(msg, err)
      throw new Error(msg + err.message)
    }
  }

  /**
   * @param {string} id
   * @param {string} fileId
   */
  async getChargingPointPhotoContentDataURLAsync(id, fileId) {
    console.log('[API] Retrieving charging point photo content...')
    let photoURL = ''
    const contentUrl = ApiService.ChargingPointPhotoContentEndpoint({
      cpId: id,
      fileId
    })

    try {
      const options = await this._getFetchOptionsAsync('GET')
      const response = await fetch(contentUrl, options)

      if (!response.ok) {
        const msg = `Error while retrieving charging point photo content: ${response.status} ${response.statusText}`
        console.error('[API] ' + msg)
        throw new Error(msg)
      } else {
        photoURL = await response.blob().then(blob => URL.createObjectURL(blob))
      }
    } catch (err) {
      const msg =
        'Uncaught error while retrieving charging point photo content: '
      console.error('[API] ' + msg, err)
      throw new Error(msg + err.message)
    }

    return photoURL
  }

  /**
   * @param {string} id
   */
  async getChargingPointPhotosAsync(id) {
    console.log(`[API] Retrieving user charging point [${id}] photos...`)
    let photos = []
    const chargingPointUrl = ApiService.ChargingPointPhotosEndpoint({
      cpId: id
    })

    try {
      const options = await this._getFetchOptionsAsync()
      const response = await fetch(chargingPointUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while fetching user charging point [${id}] photos: ${response.status} ${response.statusText}`
        )
      } else {
        photos = await response.json()
      }
    } catch (err) {
      console.error(
        `[API] Uncaught error while retrieving user charging point [${id}] photos: ` +
          err
      )
    }

    return photos
  }

  /**
   * @param {string} cpId
   * @param {string} fileId
   */
  async deleteChargingPointPhotoAsync(cpId, fileId) {
    console.log(`[API] Deleting user charging point [${cpId}] photo...`)
    const chargingPointUrl = ApiService.ChargingPointPhotoEndpoint({
      cpId,
      fileId
    })

    try {
      const options = await this._getFetchOptionsAsync('DELETE')
      const response = await fetch(chargingPointUrl, options)

      if (!response.ok) {
        const msg = `Error while deleting user charging point [${cpId}] photo: ${response.status} ${response.statusText}`
        console.error('[API] ' + msg)
        throw new Error(msg)
      }
    } catch (err) {
      const msg = `Uncaught error while retrieving user charging point [${cpId}] photos: `
      console.error('[API] ' + msg, err)
      throw new Error(msg + err.message)
    }
  }

  static ChargingPointProofOfAddressEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_ChargingPoint_Base}/\${ cpId }/proofofaddress`
  )

  static ChargingPointProofOfAddressContentEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_ChargingPoint_Base}/\${ cpId }/proofofaddress/content`
  )

  /**
   * @param {string} id
   * @param {File | Blob} file
   */
  async putChargingPointProofOfAddressAsync(id, file) {
    console.log('[API] Updating charging point proof of address...')
    const userInfoUrl = ApiService.ChargingPointProofOfAddressContentEndpoint({
      cpId: id
    })

    try {
      const formData = new FormData()
      formData.append('file', file)
      const options = await this._getFetchOptionsAsync('PUT')
      options.body = formData
      const response = await fetch(userInfoUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while puting charging point proof of address: ${response.status} ${response.statusText}`
        )
        throw new Error(
          `Error while puting charging point proof of address: ${response.status} ${response.statusText}`
        )
      }
    } catch (err) {
      console.error(
        '[API] Uncaught error while updating charging point proof of address: ',
        err
      )
      throw new Error(
        'Uncaught error while updating charging point proof of address: ' + err
      )
    }
  }

  /**
   * @param {string} id
   * @returns {Promise<Document>}
   */
  async getChargingPointProofOfAddressAsync(id) {
    console.log(`[API] Retrieving charging point proof of address...`)
    let poa = {
      file: undefined,
      type: undefined,
      url: undefined,
      isValid: undefined
    }
    const userInfoUrl = ApiService.ChargingPointProofOfAddressEndpoint({
      cpId: id
    })

    try {
      const options = await this._getFetchOptionsAsync()
      const response = await fetch(userInfoUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while fetching charging point proof of address: ${response.status} ${response.statusText}`
        )
      } else {
        const data = await response.json()

        ApiService.MapDocumentFromApi(data, poa, userInfoUrl)
      }
    } catch (err) {
      console.error(
        `[API] Uncaught error while retrieving charging point proof of address: ` +
          err
      )
    }

    return poa
  }

  static ChargingPointSubscribersEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_User_Base}/\${ userId }/${process.env.VUE_APP_API_Method_User_UserChargingPoints}/\${ cpId }/subscribers`
  )

  static ChargingPointSubscriberEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_User_Base}/\${ userId }/${process.env.VUE_APP_API_Method_User_UserChargingPoints}/\${ cpId }/subscribers/\${ email }`
  )

  /**
   * @param {string} id
   * @param {{ email: string, name: string }} guest
   */
  async postChargingPointGuestAsync(id, guest) {
    console.log(`[API] Inviting user to charging point [${id}]...`)
    const url = ApiService.ChargingPointSubscribersEndpoint({
      userId: await this._getUserId(),
      cpId: id
    })

    try {
      const options = await this._getFetchOptionsAsync('POST', guest)
      const response = await fetch(url, options)

      if (!response.ok) {
        const message = `Error while inviting user to charging point: ${response.status} ${response.statusText}`
        console.error(`[API] ` + message)
        throw new Error(message)
      }

      return response.json()
    } catch (err) {
      const message = 'Uncaught error while inviting user to charging point: '
      console.error('[API] ' + message, err)
      return Promise.reject(message + err)
    }
  }

  /**
   * @param {string} id
   * @param {string} email
   */
  async deleteChargingPointGuestAsync(id, email) {
    console.log(`[API] Removing guest from charging point [${id}]...`)
    const url = ApiService.ChargingPointSubscriberEndpoint({
      userId: await this._getUserId(),
      cpId: id,
      email
    })

    try {
      const options = await this._getFetchOptionsAsync('DELETE')
      const response = await fetch(url, options)

      if (!response.ok) {
        const message = `Error while removing guest from charging point: ${response.status} ${response.statusText}`
        console.error(`[API] ` + message)
        throw new Error(message)
      }
    } catch (err) {
      const message =
        'Uncaught error while removing guest from charging point: '
      console.error('[API] ' + message, err)
      throw new Error(message + err)
    }
  }

  // Payments

  static StripeSetupIntentEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_Stripe_Base}/getsetupintent`
  )

  static StripePaymentIntentEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_Stripe_Base}/getpaymentintent`
  )

  async getSetupIntentAsync() {
    console.log('[API] Get Setup intent...')
    const setupIntentUrl = ApiService.StripeSetupIntentEndpoint()

    try {
      const options = await this._getFetchOptionsAsync()
      const response = await fetch(setupIntentUrl, options)

      if (!response.ok) {
        throw new Error(
          `Error while geting setup intent: ${response.status} ${response.statusText}`
        )
      }

      return response.text()
    } catch (err) {
      console.error('[API] Uncaught error while getting setup intent: ', err)
    }
  }

  static UserPaymentMethodsEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_User_Base}/\${ userId }/${process.env.VUE_APP_API_Method_User_UserPaymentMethods}`
  )

  static UserPaymentMethodEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_User_Base}/\${ userId }/${process.env.VUE_APP_API_Method_User_UserPaymentMethods}/\${ pmId }`
  )

  /**
   * @param {any} setupIntent
   */
  async postUserPaymentMethodAsync(setupIntent) {
    console.log('[API] Adding new user payment method...')
    const currentUser = await this._getUser()
    const pmUrl = ApiService.UserPaymentMethodsEndpoint({
      userId: currentUser.id
    })

    try {
      const options = await this._getFetchOptionsAsync('POST', {
        // TODO: Remove user part and use credentials on server
        user: {
          name: currentUser.name,
          email: currentUser.email
        },
        setupIntent: setupIntent
      })
      const response = await fetch(pmUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while adding payment method: ${response.status} ${response.statusText}`
        )
        throw new Error(
          `Error while adding payment method: ${response.status} ${response.statusText}`
        )
      } else {
        return response.json()
      }
    } catch (err) {
      console.error('[API] Uncaught error while adding payment method: ', err)
      throw new Error('Uncaught error while adding payment method: ' + err)
    }
  }

  async getUserPaymentMethodsAsync() {
    console.log('[API] Retrieving user credit cards...')
    let creditCards = []
    const currentUser = await this._getUser()
    const pmUrl = ApiService.UserPaymentMethodsEndpoint({
      userId: currentUser.id
    })
    const creditCardsUrl = new URL(pmUrl)
    creditCardsUrl.searchParams.append('email', currentUser.email)

    try {
      const options = await this._getFetchOptionsAsync()
      const response = await fetch(creditCardsUrl.toString(), options)

      if (!response.ok) {
        console.error(
          `[API] Error while fetching user credit cards: ${response.status} ${response.statusText}`
        )
      } else {
        const result = await response.json()

        creditCards = result.data || [] // TODO: See if there isn't a cleaner way
      }
    } catch (err) {
      console.error(
        '[API] Uncaught error while retrieving user credit cards: ',
        err
      )
    }

    return creditCards
  }

  /**
   * @param {string} id
   */
  async getUserPaymentMethodAsync(id) {
    console.log(`[API] Retrieving user payment method [${id}]]...`)
    let paymentMethod = undefined
    const currentUser = await this._getUser()
    const pmUrl = ApiService.UserPaymentMethodEndpoint({
      userId: currentUser.id,
      pmId: id
    })

    const paymentMethodsUrl = new URL(pmUrl)
    paymentMethodsUrl.searchParams.append('email', currentUser.email)

    try {
      const options = await this._getFetchOptionsAsync()
      const response = await fetch(paymentMethodsUrl.toString(), options)

      if (!response.ok) {
        console.error(
          `[API] Error while fetching user payments methods: ${response.status} ${response.statusText}`
        )

        return Promise.reject()
      } else {
        const card = await response.json()

        paymentMethod = card
      }
    } catch (err) {
      console.error(
        `[API] Uncaught error while retrieving user payments method [${id}]: `,
        err
      )

      return Promise.reject(err)
    }

    return paymentMethod
  }

  /**
   * @param {any} pm
   */
  async putUserPaymentMethodAsync(pm) {
    console.log('[API] Updating new user payment method...')
    const pmUrl = ApiService.UserPaymentMethodEndpoint({
      userId: await this._getUserId(),
      pmId: pm.id
    })

    try {
      const options = await this._getFetchOptionsAsync('PUT', pm)
      const response = await fetch(pmUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while updating payment method: ${response.status} ${response.statusText}`
        )
        throw new Error(
          `Error while updating payment method: ${response.status} ${response.statusText}`
        )
      }
    } catch (err) {
      console.error('[API] Uncaught error while updating payment method: ', err)
      throw new Error('Uncaught error while updating payment method: ' + err)
    }
  }

  /**
   * @param {string} id
   *
   * @returns {Promise<any>}
   */
  async deletePaymentMethodAsync(id) {
    console.log(`[API] Deleting user payment method [${id}]...`)
    const pmUrl = ApiService.UserPaymentMethodEndpoint({
      userId: await this._getUserId(),
      pmId: id
    })

    try {
      const options = await this._getFetchOptionsAsync('DELETE')
      const response = await fetch(pmUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while deleting user payment method: ${response.status} ${response.statusText}`
        )

        return Promise.reject()
      }
    } catch (err) {
      console.error(
        '[API] Uncaught error while deleting user payment method: ',
        err
      )

      return Promise.reject()
    }

    return Promise.resolve()
  }

  /**
   * @param {Number} duration
   * @param {string} cpId
   */
  async getPaymentIntentAsync(duration, cpId) {
    console.log('[API] Get Payment intent...')
    let intent = undefined
    const paymentIntentUrl = new URL(ApiService.StripePaymentIntentEndpoint())
    paymentIntentUrl.searchParams.append('duration', duration.toString())
    paymentIntentUrl.searchParams.append('cpId', cpId)
    paymentIntentUrl.searchParams.append('email', (await this._getUser()).email)

    try {
      const options = await this._getFetchOptionsAsync()
      const response = await fetch(paymentIntentUrl.toString(), options)

      if (!response.ok) {
        console.error(
          `[API] Error while fetching payment intent: ${response.status} ${response.statusText}`
        )
      } else {
        intent = await response.json()
      }

      return intent
    } catch (err) {
      console.error('[API] Uncaught error while getting payment intent: ', err)
    }
  }

  static UserIbanEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_User_Base}/\${ userId }/iban`
  )

  /**
   * @param {any} pm
   * @param {string} iban
   */
  async putUserIbanAsync(pm, iban) {
    console.log('[API] Setting user IBAN...')
    const currentUser = await this._getUser()
    const putUserIbanUrl = ApiService.UserIbanEndpoint({
      userId: currentUser.id
    })

    try {
      const options = await this._getFetchOptionsAsync('PUT', {
        // TODO: Remove user part and use credentials on server
        user: {
          name: currentUser.name,
          email: currentUser.email
        },
        paymentMethod: pm,
        iban: iban
      })
      const response = await fetch(putUserIbanUrl, options)

      if (!response.ok) {
        const msg = `Error while setting IBAN: ${response.status} ${response.statusText}`
        console.error('[API] ' + msg)
        throw new Error(msg)
      }
    } catch (err) {
      const msg = 'Uncaught error while setting IBAN: '
      console.error('[API] ' + msg, err)
      throw new Error(msg + err.message)
    }
  }

  async getUserIbanAsync() {
    console.log(`[API] Retrieving user IBAN...`)
    let iban = null
    const currentUser = await this._getUser()
    const pmUrl = ApiService.UserIbanEndpoint({
      userId: currentUser.id
    })

    const ibanUrl = new URL(pmUrl)
    ibanUrl.searchParams.append('email', currentUser.email)

    try {
      const options = await this._getFetchOptionsAsync()
      const response = await fetch(ibanUrl.toString(), options)

      if (!response.ok) {
        const msg = `Error while fetching user IBAN: ${response.status} ${response.statusText}`
        console.error('[API] ' + msg)

        if (response.status != 404) {
          throw new Error(msg)
        }
      } else {
        iban = await response.json()
      }
    } catch (err) {
      const msg = 'Uncaught error while retrieving user IBAN: '
      console.error('[API] ' + msg, err)
      throw new Error(msg + err.message)
    }

    return iban
  }

  // Search

  static SearchChargingPointEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_Search_Base}/chargingpoints`
  )

  /**
   * @param {{longitude: number, latitude: number, distance: number}} params
   *
   * @returns {Promise<ApiChargingPoint[]>}
   */
  async searchChargingPointsAsync(params) {
    console.log('[API] Search charging points...')

    try {
      let points = []
      const searchUrl = ApiService.SearchChargingPointEndpoint()
      const options = await this._getFetchOptionsAsync('POST', params)
      const response = await fetch(searchUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while fetching search results: ${response.status} ${response.statusText}`
        )
      } else {
        points = await response.json()
      }

      return points
    } catch (err) {
      console.error(
        '[API] Uncaught error while searching charging points: ',
        err
      )
    }
  }

  /**
   * @param {ApiChargingPoint} cp
   * @param {Search} cp
   */
  static MapSearchResultFromApi(cp, search) {
    const [lon, lat] = cp.location.coordinates
    return new SearchResultCP(
      lon,
      lat,
      {
        id: cp.id,
        ownerId: cp.customerId,
        address: cp.address,
        ...ApiService.MapTypePowerFromApi(cp.plugType),
        price: cp.price,
        private: !cp.public,
        availability: cp.availability,
        bookings: cp.bookings
      },
      search
    )
  }

  // Bookings

  static UserBookingsEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_User_Base}/\${ userId }/${process.env.VUE_APP_API_Method_User_UserBookings}`
  )

  /**
   * @typedef {object} Booking
   * @property {{ id: string, ownerId: string, address: string }} chargingPoint
   * @property {{ id: string, name: string, username: string }} vehicle
   * @property {{ start: Date, end: Date}} time
   * @property {number} price
   * @property {number} basePrice
   * @property {number} estimatedDistance
   * @property {string} stripePaymentIntentId
   */

  /**
   * @param {Booking} booking
   */
  async postUserBookingAsync(booking) {
    console.log('[API] Adding user booking...')
    const userId = await this._getUserId()
    const bookingUrl = ApiService.UserBookingsEndpoint({
      userId: userId
    })

    try {
      const options = await this._getFetchOptionsAsync('POST', {
        ChargingPointId: booking.chargingPoint.id,
        OwnerId: booking.chargingPoint.ownerId,
        Address: booking.chargingPoint.address,
        UserVehicleRowKey: booking.vehicle.id,
        UserVehicleName: booking.vehicle.name,
        UserId: userId,
        Username: booking.vehicle.username,
        Start: booking.time.start,
        End: booking.time.end,
        Price: booking.price,
        BasePrice: booking.basePrice,
        EstimatedDistance: booking.estimatedDistance,
        StripePaymentIntentId: booking.stripePaymentIntentId
      })
      const response = await fetch(bookingUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while posting user booking: ${response.status} ${response.statusText}`
        )
        throw new Error(
          `Error while posting user booking: ${response.status} ${response.statusText}`
        )
      }

      return response
        .json()
        .then(booking => ApiService.MapBookingFromApi(booking))
    } catch (err) {
      console.error('[API] Uncaught error while updating user booking: ', err)
      throw new Error('Uncaught error while updating user booking: ' + err)
    }
  }

  static MapBookingFromApi(apiBooking) {
    return {
      id: apiBooking.rowKey,
      vehicle: {
        id: apiBooking.userVehicleRowKey,
        name: apiBooking.userVehicleName,
        username: apiBooking.username,
        userId: apiBooking.userId
      },
      chargingPoint: {
        id: apiBooking.chargingPointId,
        ownerId: apiBooking.ownerId,
        address: apiBooking.address
      },
      time: {
        start: new Date(apiBooking.start),
        end: new Date(apiBooking.end)
      },
      price: apiBooking.price,
      basePrice: apiBooking.basePrice,
      estimatedDistance: apiBooking.estimatedDistance
    }
  }

  async getUserBookingsAsync() {
    console.log('[API] Retrieving user bookings...')
    let bookings = []
    const bookingUrl = ApiService.UserBookingsEndpoint({
      userId: await this._getUserId()
    })

    try {
      const options = await this._getFetchOptionsAsync()
      const response = await fetch(bookingUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while fetching user bookings: ${response.status} ${response.statusText}`
        )
      } else {
        const data = await response.json()

        bookings = data.map(ApiService.MapBookingFromApi)
      }
    } catch (err) {
      console.error('[API] Uncaught error while fetching user bookings: ', err)
      throw new Error('Uncaught error while fetching user bookings: ' + err)
    }

    return bookings
  }

  // Complaints

  static UserComplaintsEndpoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_User_Base}/\${ userId }/${process.env.VUE_APP_API_Method_User_UserComplaints}`
  )

  /**
   * @param {ComplaintKind} kind
   * @param {DefaultComplaintSubject | BookingComplaintSubject} subject
   * @param {{
   *  booking?: any,
   *  username: string,
   *  message: string
   * }} complaint
   *
   * @returns {Promise}
   */
  async postUserComplaintAsync(kind, subject, complaint) {
    console.log('[API] Adding user complaint...')
    const userId = await this._getUserId()
    const complaintUrl = ApiService.UserComplaintsEndpoint({
      userId: userId
    })

    try {
      const { booking, username, message } = complaint
      const options = await this._getFetchOptionsAsync('POST', {
        kind: kind | subject,
        booking,
        username,
        message
      })
      const response = await fetch(complaintUrl, options)

      if (!response.ok) {
        const msg = `Error while posting user complaint: ${response.status} ${response.statusText}`
        console.error('[API] ' + msg)
        throw new Error(msg)
      }

      return response.json()
    } catch (err) {
      const msg = 'Uncaught error while posting user complaint: '
      console.error('[API] ' + msg, err)
      throw new Error(msg + err.message)
    }
  }

  async getUserComplaintsAsync() {
    return [
      // {
      //   id: 1,
      //   period: {
      //     date: '19 juin',
      //     year: '2019',
      //     time: {
      //       start: '15h15',
      //       end: '16h15'
      //     }
      //   },
      //   chargingPoint: {
      //     id: '3344',
      //     address: '10 RUE DE BREST LYON 69002',
      //     status: 'pending'
      //   }
      // },
      // {
      //   id: 2,
      //   period: {
      //     date: '8 avril',
      //     year: '2019',
      //     time: {
      //       start: '15h15',
      //       end: '16h15'
      //     }
      //   },
      //   chargingPoint: {
      //     id: '3344',
      //     address: '10 RUE DE BREST LYON 69002',
      //     status: 'closed'
      //   }
      // }
    ]
  }

  // Users

  static UserInfoEndPoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_User_Base}/\${ userId }`
  )

  static UserPhotoEndPoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_User_Base}/\${ userId }/photo`
  )

  static UserPhotoContentEndPoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_User_Base}/\${ userId }/photo/content`
  )

  static UserIdCardEndPoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_User_Base}/\${ userId }/idcard`
  )

  static UserIdCardContentEndPoint = _.template(
    `${process.env.VUE_APP_API_BasePath}${process.env.VUE_APP_API_Method_User_Base}/\${ userId }/idcard/content`
  )

  /**
   * @typedef {object} UserInfo
   * @property {string} id
   * @property {string} fullName
   * @property {string} email
   * @property {string} username
   */

  /**
   * @param {UserInfo} userInfo
   */
  async putUserInfoAsync(userInfo) {
    console.log('[API] Updating user info...')
    const userInfoUrl = ApiService.UserInfoEndPoint({
      userId: await this._getUserId()
    })

    try {
      const options = await this._getFetchOptionsAsync('PUT', userInfo)
      const response = await fetch(userInfoUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while puting user info: ${response.status} ${response.statusText}`
        )
        throw new Error(
          `Error while puting user info: ${response.status} ${response.statusText}`
        )
      }
    } catch (err) {
      console.error('[API] Uncaught error while updating user info: ', err)
      throw new Error('Uncaught error while updating user info: ' + err)
    }
  }

  /**
   * @returns {Promise<UserInfo>}
   */
  async getUserInfoAsync() {
    console.log('[API] Retrieving user info...')
    /** @type {UserInfo} */
    let userInfo
    const userInfoUrl = ApiService.UserInfoEndPoint({
      userId: await this._getUserId()
    })

    try {
      const options = await this._getFetchOptionsAsync()
      const response = await fetch(userInfoUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while fetching user info: ${response.status} ${response.statusText}`
        )
      } else {
        userInfo = await response.json()
      }
    } catch (err) {
      console.error('[API] Uncaught error while fetching user info: ', err)
      throw new Error('Uncaught error while fetching user info: ' + err)
    }

    return userInfo
  }

  /**
   * @param {string | Blob} file
   */
  async putUserPhotoAsync(file) {
    console.log('[API] Updating user photo...')
    const userInfoUrl = ApiService.UserPhotoContentEndPoint({
      userId: await this._getUserId()
    })

    try {
      const formData = new FormData()
      formData.append('photo', file)
      const options = await this._getFetchOptionsAsync('PUT')
      options.body = formData
      const response = await fetch(userInfoUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while puting user photo: ${response.status} ${response.statusText}`
        )
        throw new Error(
          `Error while puting user photo: ${response.status} ${response.statusText}`
        )
      }
    } catch (err) {
      console.error('[API] Uncaught error while updating user photo: ', err)
      throw new Error('Uncaught error while updating user photo: ' + err)
    }
  }

  async getUserPhotoContentDataURLAsync() {
    console.log('[API] Retrieving user photo content...')
    let photoURL = ''
    const userPhotoContentUrl = ApiService.UserPhotoContentEndPoint({
      userId: await this._getUserId()
    })

    try {
      const options = await this._getFetchOptionsAsync()
      const response = await fetch(userPhotoContentUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while fetching user photo content: ${response.status} ${response.statusText}`
        )
      } else {
        photoURL = await response.blob().then(blob => URL.createObjectURL(blob))
      }
    } catch (err) {
      console.error('[API] Uncaught error while fetching user info: ', err)
      throw new Error('Uncaught error while fetching user info: ' + err)
    }

    return photoURL
  }

  /**
   * @param {File | Blob} file
   */
  async putUserIdCardAsync(file) {
    console.log('[API] Updating user ID card...')
    const userInfoUrl = ApiService.UserIdCardContentEndPoint({
      userId: await this._getUserId()
    })

    try {
      const formData = new FormData()
      formData.append('file', file)
      const options = await this._getFetchOptionsAsync('PUT')
      options.body = formData
      const response = await fetch(userInfoUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while puting user ID card: ${response.status} ${response.statusText}`
        )
        throw new Error(
          `Error while puting user ID card: ${response.status} ${response.statusText}`
        )
      }
    } catch (err) {
      console.error('[API] Uncaught error while updating user ID card: ', err)
      throw new Error('Uncaught error while updating user ID card: ' + err)
    }
  }

  /**
   * @returns {Promise<Document>}
   */
  async getUserIdCardAsync() {
    console.log(`[API] Retrieving user ID card...`)
    let idCard = {
      file: undefined,
      type: undefined,
      url: undefined,
      isValid: undefined
    }
    const userIdCardUrl = ApiService.UserIdCardEndPoint({
      userId: await this._getUserId()
    })

    try {
      const options = await this._getFetchOptionsAsync()
      const response = await fetch(userIdCardUrl, options)

      if (!response.ok) {
        console.error(
          `[API] Error while fetching user ID card: ${response.status} ${response.statusText}`
        )
      } else {
        const data = await response.json()

        ApiService.MapDocumentFromApi(data, idCard, userIdCardUrl)
      }
    } catch (err) {
      console.error(`[API] Uncaught error while retrieving user ID card: `, err)
    }

    return idCard
  }

  /**
   * @param {{ url: RequestInfo; }} doc
   *
   * @returns {Promise<any>}
   */
  async getDocumentContent(doc) {
    console.log(`[API] Retrieving document content...`)

    return this._getFetchOptionsAsync()
      .then(options => fetch(doc.url, options))
      .then(response => {
        if (!response.ok) {
          const msg = `Error while fetching document content: ${response.status} ${response.statusText}`
          console.error('[API] ' + msg)

          return Promise.reject(msg)
        } else {
          return response.blob().then(blob => URL.createObjectURL(blob))
        }
      })
      .then(url => (doc.url = url))
      .catch(err => {
        console.error(
          `[API] Uncaught error while retrieving user ID card: `,
          err
        )

        return Promise.reject(err)
      })
  }
}
