const defaultDayAvailability = () => ({
  available: true,
  startTime: '07:00',
  endTime: '12:30'
})

const emptyDayAvailability = () => ({ available: false })

export function DefaultAvailability(filled) {
  const getAvailability = filled ? defaultDayAvailability : emptyDayAvailability

  return {
    paused: false,
    days: {
      monday: getAvailability(),
      tuesday: getAvailability(),
      wednesday: getAvailability(),
      thursday: getAvailability(),
      friday: getAvailability(),
      saturday: getAvailability(),
      sunday: getAvailability()
    }
  }
}

export const ChargingPointAvailabilityMixin = {
  computed: {
    chargingPointAvailability() {
      return this.chargingPoint && this.chargingPoint.availability
        ? this.chargingPoint.availability
        : DefaultAvailability()
    }
  },
  methods: {
    getDayLabel(key) {
      return {
        monday: 'LUNDI',
        tuesday: 'MARDI',
        wednesday: 'MERCREDI',
        thursday: 'JEUDI',
        friday: 'VENDREDI',
        saturday: 'SAMEDI',
        sunday: 'DIMANCHE'
      }[key]
    }
  }
}
