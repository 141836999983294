import { DataFetchStatus } from '@/assets/js/Constants.js'

let fetchStatus = DataFetchStatus.Unfetched
let fetchPending = undefined

const providerProfile = {
  state: {
    chargingPoints: []
  },
  getters: {
    getChargingPoint: state => id =>
      state.chargingPoints.find(cp => cp.id == id),
    getChargingPointPhotos: state => id => {
      const cp = state.chargingPoints.find(cp => cp.id == id)

      return cp ? cp.photos : undefined
    }
  },
  mutations: {
    addChargingPoint(state, cp) {
      state.chargingPoints.push(cp)
    },
    setChargingPoint(state, cp) {
      const storeCP = state.chargingPoints.find(storeCP => storeCP.id == cp.id)

      if (storeCP) {
        Object.assign(storeCP, cp)
      } else {
        state.chargingPoints.push(cp)
      }
    },
    setChargingPoints(state, cps) {
      state.chargingPoints = cps
    },
    deleteChargingPoint(state, id) {
      const idx = state.chargingPoints.findIndex(cp => cp.id == id)

      if (idx > -1) {
        state.chargingPoints.splice(idx, 1)
      }
    },
    setChargingPointPhotos(state, { cpId, photos }) {
      const cp = state.chargingPoints.find(cp => cp.id == cpId)

      if (cp) {
        cp.photos = photos
      }
    },
    setChargingPointPhotoUrl(state, { cpId, pId, url }) {
      const cp = state.chargingPoints.find(cp => cp.id == cpId)

      if (cp) {
        const photo = cp.photos.find(p => p.id == pId)

        if (photo) {
          photo.url = url
        }
      }
    },
    addChargingPointPhoto(state, { cpId, photo }) {
      const cp = state.chargingPoints.find(cp => cp.id == cpId)

      if (cp) {
        cp.photos.push(photo)
      }
    },
    deleteChargingPointPhoto(state, { cpId, photoId }) {
      const cp = state.chargingPoints.find(cp => cp.id == cpId)

      if (cp) {
        const idx = cp.photos.findIndex(p => p.id == photoId)

        if (idx > -1) {
          const photo = cp.photos.splice(idx, 1)[0]

          URL.revokeObjectURL(photo.url)
        }
      }
    },
    setChargingPointProofOfAddress(state, { cpId, poa }) {
      const cp = state.chargingPoints.find(cp => cp.id == cpId)

      if (cp) {
        cp.proofOfAddress = poa
      }
    },
    addSubscriber(state, { cpId, guest }) {
      const cp = state.chargingPoints.find(cp => cp.id == cpId)

      if (cp) {
        cp.subscribers.push(guest)
      }
    },
    deleteSubscriber(state, { cpId, email }) {
      const cp = state.chargingPoints.find(cp => cp.id == cpId)

      if (cp) {
        const idx = cp.subscribers.findIndex(s => s.email == email)

        if (idx > -1) {
          cp.subscribers.splice(idx, 1)
        }
      }
    }
  },
  actions: {
    loadChargingPoint({ commit, dispatch }, { api, id }) {
      return api
        .getCurrentUserChargingPointAsync(id)
        .then(cp => {
          if (cp) {
            cp.photos = []
            cp.proofOfAddress = {}
            commit('setChargingPoint', cp)

            dispatch('loadProofOfAddress', {
              api,
              cpId: cp.id
            })
              .then(poa => {
                commit('setChargingPointProofOfAddress', { cpId: cp.id, poa })
              })
              .catch(err => {
                console.error(
                  `[STORE] Uncaught error while loading charging point [${cp.id}] proof of address: `,
                  err
                )
              })

            dispatch('loadChargingPointPhotos', { api, cpId: cp.id }).catch(
              err => {
                console.error(
                  `[STORE] Uncaught error while loading charging point [${cp.id}] photos: `,
                  err
                )
              }
            )
          }

          return cp
        })
        .catch(err => {
          console.error(
            `[STORE] Uncaught error while loading charging point [${id}]: `,
            err
          )

          return Promise.reject(err)
        })
    },
    loadChargingPoints({ state, commit }, { api, refresh }) {
      if (fetchStatus == DataFetchStatus.Pending) {
        return fetchPending
      } else if (fetchStatus == DataFetchStatus.Unfetched || refresh) {
        fetchStatus = DataFetchStatus.Pending

        fetchPending = api
          .getUserChargingPointsAsync()
          .then(chargingPoints => {
            commit(
              'setChargingPoints',
              chargingPoints.map(cp => {
                cp.photos = []
                cp.proofOfAddress = {}

                return cp
              })
            )
            fetchStatus = DataFetchStatus.Fetched

            return state.chargingPoints
          })
          .catch(err => {
            console.error(
              '[STORE] Uncaught error while updating charging points: ',
              err
            )
            fetchStatus = DataFetchStatus.Unfetched

            return Promise.reject(err)
          })
      } else {
        return state.chargingPoints
      }
    },
    deleteChargingPoint({ getters, commit }, { api, id }) {
      return api
        .deleteUserChargingPointAsync(id)
        .then(() => {
          const photos = getters.getChargingPointPhotos(id) || []

          for (var photo of photos) {
            commit('deleteChargingPointPhoto', {
              cpId: id,
              photoId: photo.id
            })
          }

          commit('deleteChargingPoint', id)

          return Promise.resolve()
        })
        .catch(err => {
          console.error(
            `[STORE] Uncaught error while deleting charging point [${id}]: `,
            err
          )

          return Promise.reject()
        })
    },
    inviteGuest({ commit }, { api, cpId, guest }) {
      return api
        .postChargingPointGuestAsync(cpId, guest)
        .then(guest => {
          commit('addSubscriber', { cpId, guest })
        })
        .catch(err => {
          console.error(
            '[STORE] Something went wrong when inviting guest: ',
            err
          )

          return Promise.reject(err)
        })
    },
    removeGuest({ commit }, { api, cpId, email }) {
      return api
        .deleteChargingPointGuestAsync(cpId, email)
        .then(() => {
          commit('deleteSubscriber', { cpId, email })
        })
        .catch(err => {
          console.error(
            '[STORE] Something went wrong when removing guest: ',
            err
          )

          return Promise.reject(err)
        })
    },
    addChargingPointPhoto({ commit }, { api, cpId, photo }) {
      return api
        .postChargingPointPhotoAsync(cpId, photo.file)
        .then(p => {
          p.id = p.fileId
          p.url = photo.blob

          commit('addChargingPointPhoto', { cpId, photo: p })
        })
        .catch(err => {
          console.error(
            '[STORE] Uncaught error while adding new photo to charging point',
            err
          )

          return Promise.reject(err)
        })
    },
    loadChargingPointPhotos({ getters, commit }, { api, cpId }) {
      return api
        .getChargingPointPhotosAsync(cpId)
        .then(photos => {
          commit('setChargingPointPhotos', {
            cpId,
            photos: (photos || []).map(p => {
              p.id = p.fileId
              p.url = undefined

              api
                .getChargingPointPhotoContentDataURLAsync(
                  p.resourceId,
                  p.fileId
                )
                .then(url =>
                  commit('setChargingPointPhotoUrl', { cpId, pId: p.id, url })
                )
                .catch(err => {
                  console.error(
                    `[STORE] Uncaught error while loading charging point [${cpId}] photo [${p.fileId}] content: `,
                    err
                  )
                })

              return p
            })
          })

          return getters.getChargingPointPhotos(cpId)
        })
        .catch(err => {
          console.error(
            `[STORE] Uncaught error while loading charging point photos: ${cpId}`,
            err
          )
        })
    },
    deleteChargingPointPhoto({ commit }, { api, cpId, photoId }) {
      return api
        .deleteChargingPointPhotoAsync(cpId, photoId)
        .then(() => {
          console.log('Picture deleted successfully!')
          commit('deleteChargingPointPhoto', { cpId, photoId })
        })
        .catch(err => {
          console.error(
            `[STORE] Uncaught error while deleting cp [${cpId}] photo [${photoId}]: `,
            err
          )

          return Promise.reject(err)
        })
    }
  }
}

export default providerProfile
