// @ts-check

import Vue from 'vue'
import AuthService from './services/auth.js'
import ApiService from './services/api-services.js'

//TODO: stop using prototype. Look for DI instead ?
const authService = new AuthService()
Vue.prototype.$AuthService = authService

const apiService = new ApiService(authService)
Vue.prototype.$ApiService = apiService

export { authService, apiService }
