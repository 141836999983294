<template>
  <div id="app">
    <router-view v-if="!appLoaded" name="loader" />
    <router-view v-else-if="charterVisible" name="charter-tou" />
    <router-view v-else-if="menuVisible" name="menu" />
    <router-view v-else />
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import LocalStorage from '@/store/local-storage'
import { isLoggedIn, isNew } from '@/utils/auth-utils'
import { Routes } from '@/router.js'

export default {
  name: 'app',
  computed: {
    ...mapState(['menuVisible', 'appLoaded']),
    ...mapGetters(['charterVisible'])
  },
  methods: {
    ...mapMutations(['setAppLoaded']),
    ...mapActions([
      'saveUserInfo',
      'loadUserInfo',
      'loadUserPhoto',
      'loadIdCard',
      'loadVehicleTypes',
      'loadChargingPointTypes',
      'loadVehicles',
      'loadChargingPoints',
      'loadCreditCards',
      'loadIban',
      'loadBookings'
    ])
  },
  created() {
    console.log('[APP] created')
  },
  async mounted() {
    console.log('[APP] mounted')

    // TODO: THIS DOESN't WORK
    // TODO: See if callback can NOT be a Controller
    if (this.$router.currentRoute.name == Routes.CALLBACK.name) {
      console.log("[App] Don't fetch in callback as it ends up fetching twice.")
      return
    }

    if (!(await isLoggedIn())) {
      console.log('[App] Cannot fetch user data: User not logged in.')

      this.setAppLoaded()

      return
    }

    this.loadVehicleTypes({ api: this.$apiService }).catch(err =>
      console.error('[App] Uncaught error while loading vehicle types: ', err)
    )

    this.loadChargingPointTypes({ api: this.$apiService }).catch(err =>
      console.error(
        '[App] Uncaught error while loading charging point types: ',
        err
      )
    )

    if (isNew()) {
      console.log('[App] User is new, saving it to database')

      this.saveUserInfo({
        userInfo: LocalStorage.user,
        api: this.$apiService
      }).catch(err =>
        console.error('[App] uncaught error while saving user info: ', err)
      )

      this.setAppLoaded()

      return
    } else {
      const dependencies = []

      dependencies.push(
        this.loadUserInfo({ api: this.$apiService })
          .then(u => {
            console.debug('[App] userinfo: ', u)
            if (!u.tou) {
              console.log('Need user to accept TOU')
            }
          })
          .catch(err =>
            console.error('[App] Uncaught error while loading user info: ', err)
          )
      )

      this.loadUserPhoto({ api: this.$apiService }).catch(err =>
        console.error('[App] Uncaught error while loading user photo: ', err)
      )

      dependencies.push(
        this.loadIdCard({ api: this.$apiService }).catch(err =>
          console.error(
            "[App] Uncaught error while updating store's user ID card: ",
            err
          )
        )
      )

      dependencies.push(
        this.loadVehicles({ api: this.$apiService }).catch(err =>
          console.error('[App] Uncaught error while loading vehicles: ', err)
        )
      )

      this.loadChargingPoints({ api: this.$apiService }).catch(err =>
        console.error(
          '[App] Uncaught error while loading charging points: ',
          err
        )
      )

      dependencies.push(
        this.loadCreditCards({ api: this.$apiService }).catch(err =>
          console.error(
            '[App] Uncaught error while loading credit cards: ',
            err
          )
        )
      )

      this.loadIban({ api: this.$apiService }).catch(err =>
        console.error('[App] Uncaught error while loading IBAN: ', err)
      )

      this.loadBookings({ api: this.$apiService }).catch(err =>
        console.error('[App] Uncaught error while loading bookings: ', err)
      )

      Promise.all(dependencies).then(() => this.setAppLoaded())
    }
  }
}
</script>
<style lang="scss">
@import '@/styles.scss';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700&display=swap');

#app,
#tou-modal,
.modal {
  min-height: 100%;
  font-family: $font-family;
  font-style: normal;
  font-size: 13px;
  font-weight: $font-weight-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $dark;

  @import '@/styles-bg.scss';

  .main-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .btn {
    height: 48px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 10px;
    border: none;
    font-weight: $font-weight-bold;
    font-size: 13px;
    line-height: 48px;
    text-transform: uppercase;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.22);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.22);

    &:hover {
      background-color: #19e6d0;
      color: $dark;
    }

    &:active {
      background-color: $button-active;
      color: #fff;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }

    &:disabled {
      background-color: #dbdbdb;
      color: #707579;
      opacity: 1;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  .btn-dark {
    background-color: $dark;
    color: #fff;
  }

  .btn-light {
    background-color: #fff;
    color: $dark;
  }

  .btn-warning {
    background-color: $invalid-data-color;
    color: #fff;
  }

  .btn-sm {
    height: 24px;
    line-height: 24px;
    border-radius: 5px;
  }

  form {
    text-align: left;
  }

  .col-form-label {
    font-size: 13px;
    font-weight: $font-weight-bold;
  }

  input,
  .custom-select {
    border: none;
    border-bottom: 1px solid black;
    border-radius: 0;
    font-size: 14px;
    font-weight: $font-weight-regular;
  }
}

.modal-content {
  border-radius: 10px;

  .modal-header,
  .modal-footer {
    border: none;
  }
}
</style>
