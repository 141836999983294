import Vue from 'vue'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFile,
  faIdCard,
  faFileAlt,
  faCrosshairs,
  faSlidersH,
  faRedoAlt
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFile, faIdCard, faFileAlt, faCrosshairs, faSlidersH, faRedoAlt)

Vue.component('fa-icon', FontAwesomeIcon)

import UUID from 'vue-uuid'
Vue.use(UUID)

import bFormSlider from 'vue-bootstrap-slider'
import 'bootstrap-slider/dist/css/bootstrap-slider.css'
Vue.use(bFormSlider)

import VueUploadComponent from 'vue-upload-component'
Vue.use(VueUploadComponent)

import VueAzureMaps from 'vue-azure-maps'
//import 'vue-azure-maps/dist/vue-azure-maps.css'
Vue.use(VueAzureMaps, {
  key: process.env.VUE_APP_AZURE_MAPS_API_Key
})

import './registerServiceWorker'

Vue.config.productionTip = false

import VueAppInsights from 'vue-application-insights'

Vue.use(VueAppInsights, {
  id: process.env.VUE_APP_APP_INSIGHT_KEY,
  trackInitialPageView: true
})

import LoaderDirective from '@/directives/loader.js'
Vue.directive('loader', LoaderDirective)

import router from './router'
import { authService, apiService } from './services'
import store from './store'
import App from './App.vue'

new Vue({
  el: '#app',
  router,
  authService,
  apiService,
  store,
  render: h => h(App)
})
