/** @enum {string} */
export const SearchWhen = Object.freeze({
  Now: 'Now',
  Today: 'Today',
  Tomorrow: 'Tomorrow',
  DayAfterTomorrow: 'DayAfterTormorrow',
  DayAfterDayAfterTomorrow: 'DayAfterDayAfterTormorrow'
})

/** @enum {string} */
export const UserType = Object.freeze({
  Driver: 'driver',
  Provider: 'provider'
})

/** @enum {string} */
export const DataFetchStatus = Object.freeze({
  Unfetched: 'unfetched',
  Pending: 'pending',
  Fetched: 'fetched'
})

/** @enum {string} */
export const Parkability = Object.freeze({
  Free: 'Libre',
  Secured: 'Sécurisé'
})

/** @enum {string} */
export const Accessibility = Object.freeze({
  Auto: 'Automatique',
  ByCard: 'Par badge'
})

/** @enum {number} */
export const ComplaintKind = Object.freeze({
  DefaultComplaint: 0x0,
  BookingComplaint: 0x1 << 4
})

/** @enum {number} */
export const DefaultComplaintSubject = Object.freeze({
  ProfileIssue: 0,
  BillingIssue: 1,
  OtherQuestion: 2
})

/** @enum {number} */
export const BookingComplaintSubject = Object.freeze({
  CannotFindCP: 0,
  CannotAccessCP: 1,
  CPBusy: 2,
  CPNotWorking: 3
})
