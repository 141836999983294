import Vue from 'vue'

export async function requireAuth(to, from, next) {
  const isLogged = await isLoggedIn()

  if (!isLogged) {
    next(false)
    Vue.prototype.$AuthService.loginRedirect()
  } else {
    next()
  }
}

export async function isLoggedIn() {
  return await Vue.prototype.$AuthService.isLoggedIn()
}

export function isNew() {
  return Vue.prototype.$AuthService.userIsNew()
}

export function getUserId() {
  return Vue.prototype.$AuthService.getUserId()
}
