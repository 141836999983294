import { DataFetchStatus } from '@/assets/js/Constants.js'

let fetchStatus = DataFetchStatus.Unfetched
let fetchPending = undefined

const driverProfile = {
  state: {
    vehicles: []
  },
  getters: {
    getVehicle: state => vId => state.vehicles.find(v => v.id == vId)
  },
  mutations: {
    addVehicle(state, vehicle) {
      state.vehicles.push(vehicle)
    },
    setVehicle(state, vehicle) {
      const v = state.vehicles.find(v => v.id == vehicle.id)

      if (v) {
        Object.assign(v, vehicle)
      } else {
        state.vehicles.push(vehicle)
      }
    },
    setVehicles(state, vehicles) {
      state.vehicles = vehicles
    },
    deleteVehicle(state, vId) {
      const idx = state.vehicles.findIndex(v => v.id == vId)

      if (idx > -1) {
        state.vehicles.splice(idx, 1)
      }
    },
    setVehicleRegistrationCertificate(state, { vId, cert }) {
      const v = state.vehicles.find(v => v.id == vId)

      if (v) {
        v.registrationCertificate = cert
      }
    },
    setVehicleRegistrationCertificateContentDataURL(state, { vId, url }) {
      console.log(url)
      const v = state.vehicles.find(v => v.id == vId)
      console.log(v.registrationCertificate)
      if (v) {
        v.registrationCertificate.url = url
      }
    }
  },
  actions: {
    loadVehicle({ commit, dispatch }, { api, id }) {
      return api
        .getUserVehicleAsync(id)
        .then(vehicle => {
          if (vehicle) {
            commit('setVehicle', vehicle)

            dispatch('loadRegistrationCertificate', {
              api,
              vId: vehicle.id
            }).then(cert => {
              commit('setVehicleRegistrationCertificate', {
                vId: vehicle.id,
                cert
              })

              api
                .getVechicleRegistrationCertificateContentDataURLAsync(
                  vehicle.id
                )
                .then(url => {
                  commit('setVehicleRegistrationCertificateContentDataURL', {
                    vId: vehicle.id,
                    url
                  })
                })
            })

            return vehicle
          }
        })
        .catch(err => {
          console.error(
            `[STORE] Uncaught error while loading vehicle [${id}]: `,
            err
          )

          return Promise.reject(err)
        })
    },
    loadVehicles({ state, commit, dispatch }, { api, refresh }) {
      if (fetchStatus == DataFetchStatus.Pending) {
        return fetchPending
      } else if (fetchStatus == DataFetchStatus.Unfetched || refresh) {
        fetchStatus = DataFetchStatus.Pending

        fetchPending = api
          .getUserVehiclesAsync()
          .then(vehicles => {
            commit('setVehicles', vehicles)
            Promise.all(
              vehicles.map(vehicle =>
                dispatch('loadRegistrationCertificate', {
                  api,
                  vId: vehicle.id
                })
                  .then(cert => {
                    commit('setVehicleRegistrationCertificate', {
                      vId: vehicle.id,
                      cert
                    })
                  })
                  .catch(err => {
                    console.error(
                      '[STORE] Uncaught error while loading certificate: ',
                      err
                    )
                  })
              )
            ).then(() => {
              fetchStatus = DataFetchStatus.Fetched
            })

            return Promise.resolve(state.vehicles)
          })
          .catch(err => {
            console.error(
              '[STORE] Uncaught error while updating vehicles: ',
              err
            )
            fetchStatus = DataFetchStatus.Unfetched

            return Promise.reject(err)
          })

        return fetchPending
      } else {
        return state.vehicles
      }
    },
    deleteVehicle({ commit }, { api, vId }) {
      return api
        .deleteUserVehicleAsync(vId)
        .then(() => {
          commit('deleteVehicle', vId)

          return Promise.resolve()
        })
        .catch(err => {
          console.error(
            `[STORE] Uncaught error while deleting vehicle [${vId}]: `,
            err
          )

          return Promise.reject()
        })
    }
  }
}

export default driverProfile
