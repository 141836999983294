const search = {
  state: {
    destination: {
      address: '',
      position: {
        lon: 0,
        lat: 0
      }
    },
    when: undefined,
    time: undefined,
    duration: 0
  },
  mutations: {
    setDestination(state, destination) {
      state.destination = destination
    },
    setWhen(state, when) {
      state.when = when
    },
    setTime(state, time) {
      state.time = time
    },
    setDuration(state, duration) {
      state.duration = duration
    }
  },
  actions: {
    setSearch({ commit }, search) {
      commit('setDestination', search.destination)
      commit('setWhen', search.when)
      commit('setTime', search.time)
      commit('setDuration', search.duration)
    }
  }
}

export default search
