// @ts-check

import { SearchWhen } from '../assets/js/Constants.js'
const moment = require('moment')

/**
 * @param {moment.Duration | number} duration
 */
export function formatDuration(duration) {
  /** @type {moment.Duration} */
  let _duration

  if (typeof duration === 'number') {
    _duration = moment.duration(duration, 'm')
  } else {
    _duration = duration
  }

  const hours = Math.trunc(_duration.asHours())
  const minutes = _duration.minutes()

  return hours
    ? hours + 'h' + (minutes ? ('' + minutes).padStart(2, '0') : '')
    : minutes + ' min'
}

/**
 * @typedef {object} DayAvailability
 * @property {boolean} available
 * @property {string} startTime
 * @property {string} endTime
 */

/**
 * @typedef {object} WeekAvailability
 * @property {DayAvailability} monday
 * @property {DayAvailability} tuesday
 * @property {DayAvailability} wednesday
 * @property {DayAvailability} thursday
 * @property {DayAvailability} friday
 * @property {DayAvailability} saturday
 * @property {DayAvailability} sunday
 */

/**
 * @typedef {object} ChargingPointAvailability
 * @property {boolean} paused
 * @property {WeekAvailability} days
 */

/**
 * @typedef {object} SearchResultCPProperties
 * @property {string} id
 * @property {string} ownerId
 * @property {string} address
 * @property {string} type
 * @property {string} power
 * @property {number} price
 * @property {boolean} private
 * @property {ChargingPointAvailability} availability
 * @property {any[]} bookings
 */

/**
 * @typedef {object} SearchDestination
 * @property {string} address
 * @property {{ lon: number, lat: number }} position
 */

export class Search {
  /**
   * @constructor
   * @param {SearchDestination} destination
   * @param {SearchWhen} when
   * @param {string} time
   * @param {number} duration
   */
  constructor(destination, when, time, duration) {
    this.destination = destination
    this.when = when
    this.time = time
    this.duration = duration
  }

  /** @type {moment.Moment} */
  get startMoment() {
    const dateStart = moment() // SearchWhen.Now
    switch (this.when) {
      case SearchWhen.Tomorrow:
        dateStart.add(1, 'day')
        break
      case SearchWhen.DayAfterTomorrow:
        dateStart.add(2, 'day')
        break
      case SearchWhen.DayAfterDayAfterTomorrow:
        dateStart.add(3, 'day')
        break
    }
    const [hours, minutes] = this.time.split(':')
    if (this.when != SearchWhen.Now) {
      dateStart
        .hours(+hours)
        .minutes(+minutes)
        .seconds(0)
    }
    return dateStart
  }

  /** @type {moment.Moment} */
  get endMoment() {
    return this.startMoment.clone().add(this.duration, 'minute')
  }

  /** @type {moment.Duration} */
  get durationMoment() {
    return moment.duration(this.duration, 'm')
  }

  get startDay() {
    switch (this.startMoment.day()) {
      case 0:
        return 'sunday'
      case 1:
        return 'monday'
      case 2:
        return 'tuesday'
      case 3:
        return 'wednesday'
      case 4:
        return 'thursday'
      case 5:
        return 'friday'
      case 6:
        return 'saturday'
      default:
        return 'sunday'
    }
  }
}

export class SearchResultCP {
  /**
   * @constructor
   * @param {number} longitude
   * @param {number} latitude
   * @param {SearchResultCPProperties} properties
   * @param {Search} search
   */
  constructor(longitude, latitude, properties, search) {
    this.longitude = longitude
    this.latitude = latitude
    this.properties = properties
    this.search = search
  }

  get dayAvailability() {
    return this.properties.availability.days[this.search.startDay]
  }

  /** @type {moment.Moment} */
  get startMoment() {
    const [hours, minutes] = this.dayAvailability.startTime.split(':')
    const cpSD = this.search.startMoment.clone()
    cpSD
      .hours(+hours)
      .minutes(+minutes)
      .seconds(0)
    return cpSD
  }

  /** @type {moment.Moment} */
  get endMoment() {
    const [hours, minutes] = this.dayAvailability.endTime.split(':')
    const cpED = this.search.endMoment.clone()
    cpED
      .hours(+hours)
      .minutes(+minutes)
      .seconds(0)
    return cpED
  }

  /** @type {moment.Moment} */
  get computedStartMoment() {
    return !this.startsTooLate
      ? this.search.startMoment
      : !this.overlapsPreviousBookingEnd
      ? this.startMoment
      : moment.max(
          this.properties.bookings
            .map(b => moment(b.end))
            .filter(bEnd =>
              bEnd.isBetween(this.search.startMoment, this.search.endMoment)
            )
        )
  }

  /** @type {moment.Moment} */
  get computedEndMoment() {
    return !this.endsTooEarly
      ? this.search.endMoment
      : !this.overlapsPreviousBookingStart
      ? this.endMoment
      : moment.min(
          this.properties.bookings
            .map(b => moment(b.start))
            .filter(bStart =>
              bStart.isBetween(this.search.startMoment, this.search.endMoment)
            )
        )
  }

  get isPausedOrUnavailable() {
    return (
      this.properties.availability &&
      (this.properties.availability.paused || !this.dayAvailability.available)
    )
  }

  get isAvailableBefore() {
    return (
      !this.isPausedOrUnavailable &&
      this.startMoment.isBefore(this.search.startMoment)
    )
  }

  get isAvailableDuring() {
    return (
      !this.isPausedOrUnavailable &&
      !this.overlapsPreviousBooking &&
      (this.startMoment.isBefore(this.search.endMoment) &&
        this.endMoment.isAfter(this.search.startMoment))
    )
  }

  get isAvailableAfter() {
    return (
      !this.isPausedOrUnavailable &&
      this.endMoment.isAfter(this.search.endMoment)
    )
  }

  get startsTooLate() {
    return (
      !this.isNotAvailableAtAll &&
      (this.startMoment.isBetween(
        this.search.startMoment,
        this.search.endMoment
      ) ||
        this.overlapsPreviousBookingEnd)
    )
  }

  get endsTooEarly() {
    return (
      !this.isNotAvailableAtAll &&
      (this.endMoment.isBetween(
        this.search.startMoment,
        this.search.endMoment
      ) ||
        this.overlapsPreviousBookingStart)
    )
  }

  get isNotAvailableAtAll() {
    return (
      this.isPausedOrUnavailable ||
      this.search.endMoment.isSameOrBefore(this.startMoment) ||
      this.search.startMoment.isSameOrAfter(this.endMoment) ||
      this.overlapsPreviousBooking
    )
  }

  get overlapsPreviousBooking() {
    return (
      this.properties.bookings.findIndex(
        b =>
          (moment(b.start).isAfter(this.search.startMoment) &&
            moment(b.end).isBefore(this.search.endMoment)) ||
          (moment(b.start).isBefore(this.search.startMoment) &&
            moment(b.end).isAfter(this.search.endMoment))
      ) > -1
    )
  }

  get overlapsPreviousBookingEnd() {
    return (
      this.properties.bookings.findIndex(b =>
        this.search.startMoment.isBetween(moment(b.start), moment(b.end))
      ) > -1
    )
  }

  get overlapsPreviousBookingStart() {
    return (
      this.properties.bookings.findIndex(b =>
        this.search.endMoment.isBetween(moment(b.start), moment(b.end))
      ) > -1
    )
  }
}
