import { DataFetchStatus } from '@/assets/js/Constants.js'

let fetchStatus = {
  vehicles: DataFetchStatus.Unfetched,
  chargingPoints: DataFetchStatus.Unfetched
}

const references = {
  state: {
    vehicleTypes: [],
    chargingPointTypes: []
  },
  getters: {
    vehicleTypes: state => state.vehicleTypes,
    getVehicleSpecifications: state => key => {
      const vType = state.vehicleTypes.find(
        t => t.versions.findIndex(v => v.rowKey == key) != -1
      )

      if (vType) {
        const specs = vType.versions.find(v => v.rowKey == key)

        return {
          brand: vType.brand,
          label: vType.label,
          version: specs
        }
      }

      return undefined
    },
    chargingPointTypes: state => state.chargingPointTypes
  },
  mutations: {
    setVehicleTypes(state, vTypes) {
      state.vehicleTypes = vTypes
    },
    setChargingPointTypes(state, cpTypes) {
      state.chargingPointTypes = cpTypes
    }
  },
  actions: {
    loadVehicleTypes({ commit }, { api, refresh }) {
      if (fetchStatus.vehicles == DataFetchStatus.Unfetched || refresh) {
        fetchStatus.vehicles = DataFetchStatus.Pending

        return api
          .getVehicleTypesAsync()
          .then(vTypes => {
            commit('setVehicleTypes', vTypes)
            fetchStatus.vehicles = DataFetchStatus.Fetched

            return Promise.resolve()
          })
          .catch(err => {
            console.error(
              '[STORE] Uncaught error while updating vehicle types: ',
              err
            )
            fetchStatus.vehicles = DataFetchStatus.Unfetched

            return Promise.reject(err)
          })
      }
    },
    loadChargingPointTypes({ commit }, { api, refresh }) {
      if (fetchStatus.chargingPoints == DataFetchStatus.Unfetched || refresh) {
        fetchStatus.chargingPoints = DataFetchStatus.Pending

        return api
          .getChargingPointTypesAsync()
          .then(cpTypes => {
            commit('setChargingPointTypes', cpTypes)
            fetchStatus.chargingPoints = DataFetchStatus.Fetched

            return Promise.resolve()
          })
          .catch(err => {
            console.error(
              '[STORE] Uncaught error while updating charging point types: ',
              err
            )
            fetchStatus.chargingPoints = DataFetchStatus.Unfetched

            return Promise.reject(err)
          })
      }
    }
  }
}

export default references
